<script>
import ModalBigger from './ModalCompact.vue';
import { useMainStore } from '../../../stores/index';
export default {
  components: {
    ModalBigger
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
    };
  },

  methods: {
    openModal () {
      this.$refs.viewDocument.openModal();
    }

  }
};

</script>

<style></style>

<template>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header>
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div class="d-flex align-items-center">
                          <i class="fa-solid fa-circle-info mr-2"></i>
                          <span class="font-docs text-black text-bold" style="font-size: 20px">Preencha todos os dados obrigatórios</span>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>

              <div>
                <span>
                  Os dados obrigatórios estão marcados com um <b style="color: red;">*</b> (asterisco vermelho) para identificação
                </span>
              </div>
            </template>

            <template #footer>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary background-Docs" style="width: 100px;" @click="$refs.viewDocument.closeModal()">Ok</button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
