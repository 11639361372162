<script>
import { useMainStore } from '../../../stores/index';

export default {
  name: 'SignerPen',
  props: {
    penShow: {
      type: Object,
      required: false
    }
  },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },

  methods: {
    DocumentIdSigner (value) {
      this.$emit('IdDocument-value', value);
    }
  }
};
</script>

<template>
  <div>
    <div v-if="penShow.data !== undefined">
        <div v-if="penShow.data.signers.length >= 1 && penShow.data.attachments.length >= 1">
                <div v-for="(attachmentsSigner, index) in penShow.data.attachments" :key="index">
                    <div v-if="penShow.data.signers.some(signer => signer.user.id === Store.user.id) && attachmentsSigner.signers.some(attachmentSigner => attachmentSigner.user_id === Store.user.id)">
                        <div v-if="index === 0">
                        <div v-for="penSigner in penShow.data.signers" :key="penSigner">
                            <div v-for="AttachSigner in attachmentsSigner.signers" :key="AttachSigner">
                            <div v-if="penSigner.user.id === Store.user.id && AttachSigner.user_id === Store.user.id">
                                <div
                                v-if="penSigner.signed_at === null && AttachSigner.signed_at === null"
                                >
                                <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentIdSigner(penShow.data.id)" data-toggle="modal" data-target="#SignerWithAttach"/>
                                </div>
                                <div
                                v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at === null"
                                >
                                <font-awesome-icon  :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentIdSigner(penShow.data.id)" data-toggle="modal" data-target="#SignerAttach"/>
                                </div>
                                <div
                                v-else-if="penSigner.signed_at === null && AttachSigner.signed_at !== null"
                                >
                                <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentIdSigner(penShow.data.id)" data-toggle="modal" data-target="#Signer"/>
                                </div>
                                <div
                                v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null && this.$route.name !== 'Assinaturas'"
                                >
                                <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                                </div>
                                <div
                                v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null && this.$route.name === 'Assinaturas'"
                                >
                                <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div v-if="penShow.data.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                        <div v-if="index === 0">
                            <div v-for="penSigner in penShow.data.signers" :key="penSigner">
                                <div v-if="penSigner.user.id === this.Store.user.id">
                                    <font-awesome-icon v-if="penSigner.signed_at === null" :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentIdSigner(penShow.data.id)" data-toggle="modal" data-target="#Signer"/>
                                    <font-awesome-icon v-if="penSigner.signed_at !== null" :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="!penShow.data.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                        <div v-if="index === 0">
                            <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="penShow.data.signers.length >= 1 && penShow.data.attachments.length <= 0">
                <div v-if="penShow.data.signers.some(signer => signer.user.id === this.Store.user.id)">
                    <div v-for="penSigner in penShow.data.signers" :key="penSigner">
                        <div v-if="penSigner.user.id === this.Store.user.id">
                            <font-awesome-icon v-if="penSigner.signed_at === null" :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 18px !important;cursor:pointer" @click="DocumentIdSigner(penShow.data.id)" data-toggle="modal" data-target="#Signer"/>
                            <font-awesome-icon v-if="penSigner.signed_at !== null" :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="penShow.data.signers.length <= 0 && penShow.data.attachments.length <= 0">
                <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
            </div>

            <div v-if="penShow.data.signers.length <= 0 && penShow.data.attachments.length >= 1">
                <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 18px !important; color:#ADB5BD"/>
            </div>
    </div>
    <div v-else>
        <div v-if="penShow.signers.length >= 1 && penShow.attachments.length >= 1">
        <div v-for="(attachmentsSigner, index) in penShow.attachments" :key="index">
            <div v-if="penShow.signers.some(signer => signer.user.id === Store.user.id) && attachmentsSigner.signers.some(attachmentSigner => attachmentSigner.user_id === Store.user.id)">
                <div v-if="index === 0">
                <div v-for="penSigner in penShow.signers" :key="penSigner">
                    <div v-for="AttachSigner in attachmentsSigner.signers" :key="AttachSigner">
                    <div v-if="penSigner.user.id === Store.user.id && AttachSigner.user_id === Store.user.id">
                        <div
                        v-if="penSigner.signed_at === null && AttachSigner.signed_at === null"
                        >
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 25px !important;cursor:pointer" @click="DocumentIdSigner(penShow.id)" data-toggle="modal" data-target="#SignerWithAttach"/>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at === null"
                        >
                        <font-awesome-icon  :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 25px !important;cursor:pointer" @click="DocumentIdSigner(penShow.id)" data-toggle="modal" data-target="#SignerAttach"/>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at === null && AttachSigner.signed_at !== null"
                        >
                        <font-awesome-icon :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 25px !important;cursor:pointer" @click="DocumentIdSigner(penShow.id)" data-toggle="modal" data-target="#Signer"/>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null && this.$route.name !== 'Assinaturas'"
                        >
                        <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null && this.$route.name === 'Assinaturas'"
                        >
                        <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div v-if="penShow.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                <div v-if="index === 0">
                    <div v-for="penSigner in penShow.signers" :key="penSigner">
                        <div v-if="penSigner.user.id === this.Store.user.id">
                            <font-awesome-icon v-if="penSigner.signed_at === null" :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 25px !important;cursor:pointer" @click="DocumentIdSigner(penShow.id)" data-toggle="modal" data-target="#Signer"/>
                            <font-awesome-icon v-if="penSigner.signed_at !== null" :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!penShow.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                <div v-if="index === 0">
                    <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
                </div>
            </div>
        </div>
    </div>

    <div v-if="penShow.signers.length >= 1 && penShow.attachments.length <= 0">
        <div v-if="penShow.signers.some(signer => signer.user.id === this.Store.user.id)">
            <div v-for="penSigner in penShow.signers" :key="penSigner">
                <div v-if="penSigner.user.id === this.Store.user.id">
                    <font-awesome-icon v-if="penSigner.signed_at === null" :icon="['fas', 'pen-clip']" class="Color-Docs" style="font-size: 25px !important;cursor:pointer" @click="DocumentIdSigner(penShow.id)" data-toggle="modal" data-target="#Signer"/>
                    <font-awesome-icon v-if="penSigner.signed_at !== null" :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
                </div>
            </div>
        </div>
    </div>

    <div v-if="penShow.signers.length <= 0 && penShow.attachments.length <= 0">
        <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
    </div>

    <div v-if="penShow.signers.length <= 0 && penShow.attachments.length >= 1">
        <font-awesome-icon :icon="['fas', 'pen-clip']" style="font-size: 25px !important; color:#ADB5BD"/>
    </div>
    </div>
</div>
</template>
