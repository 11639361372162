<script>
import { useMainStore } from '../../../stores/index';

export default {
  name: 'RequiredSigner',
  props: {
    ReqSigner: {
      type: Object,
      required: false
    }
  },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  }
};
</script>

<template>
  <div>
    <div v-if="ReqSigner.data.attachments.length >= 1">
        <div v-for="(attachmentsSigner, index) in ReqSigner.data.attachments" :key="index">
            <div v-if="attachmentsSigner.signers.some(attachmentSigner => attachmentSigner.user_id === Store.user.id)">
                <div v-if="index === 0">
                    <div v-for="AttachSigner in attachmentsSigner.signers" :key="AttachSigner">
                    <div v-if="AttachSigner.user_id === Store.user.id">
                        <div
                        v-if="AttachSigner.is_required === true"
                        >
                        <span class="font-docs font-weight-bold">Sim</span>
                        </div>
                        <div
                        v-else-if="AttachSigner.is_required === false"
                        >
                        <span class="font-docs font-weight-bold">Não</span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <span class="font-docs font-weight-bold">Não</span>
    </div>
 </div>
</template>
