<script>
export default {
  name: 'ModalChoseUser',
  data () {
    return {
      show: false
    };
  },
  methods: {
    closeModal () {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal () {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }
};
</script>
  <style lang="scss" scoped>
  .modals {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    &__backdrop {
      background-color: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    &__dialog {
      background-color: #ffffff;
      position: relative;
      width: 40%;
      margin: 265px auto;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      z-index: 2;
      @media screen and (max-width: 992px) {
        width: 40%;
      }
    }
    &__close {
      width: 30px;
      height: 30px;
    }
    &__header {
      padding: 10px 20px 10px;
      display: block;
      align-items: flex-start;
      justify-content: space-between;
      border: 1px solid #ddd;
    }
    &__body {
      padding: 10px 20px 10px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    &__footer {
      padding: 10px 20px 20px;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
<template>
    <transition name="fade">
      <div v-if="show" class="modals">
        <div class="modals__backdrop" @click="closeModal()" />
        <div class="modals__dialog">
          <div class="modals__header">
            <slot name="header" />
          </div>
          <div class="modals__body">
            <slot name="body" />
          </div>
          <div class="modals__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
</template>
