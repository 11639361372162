<script>
/* import { AxiosAPI } from '@/axios'; */
import { useMainStore } from '../../../stores/index';
import ModalChoseUser from './ModalChoseUser.vue';
export default {
  name: 'ModalResponsable',
  components: { ModalChoseUser },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      responsableTask: this.Store.user,
      responsableTaskEdit: [],
      edit: false
    };
  },

  methods: {
    openModal () {
      this.$refs.viewDocument.openModal();
    },

    openModalEdit () {
      this.edit = true;
      this.responsableTaskEdit = this.Store.EditresponsableTask;
      this.$refs.viewDocument.openModal();
    },

    ChangeResponsable () {
      this.Store.responsableTask = this.responsableTask;
      this.$refs.viewDocument.closeModal();
    },

    EditChangeResponsable () {
      this.Store.EditresponsableTask = this.responsableTaskEdit;
      this.$refs.viewDocument.closeModal();
    }
  }
};
</script>
<style scoped>
.dropdown-content p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}
.dropdown-content p:hover {
  background-color: #f0f0f0;
}
</style>
<template>
    <div>
        <ModalChoseUser ref="viewDocument">
            <template #header>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                      <span class="font-docs"><b>Editar responsável pela tarefa</b></span>
                  </div>
                  <div>

                      <button
                      id="close"
                      type="button"
                      class="close"
                      @click="$refs.viewDocument.closeModal();"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>

                  </div>
                </div>
            </template>
            <template #body>
                <div v-if="!edit">
                    <Dropdown
                    v-model="responsableTask"
                    :options="this.Store.users"
                    filter
                    optionLabel="name"
                    placeholder="Select a Country"
                    style="z-index: 9999 !important; height: calc(4rem + 2px);"
                    class="w-full my_form">
                        <template #value="slotProps">
                            <div class="d-flex align-items-center">
                                <img :alt="slotProps.value.name" :src="this.Store.host + slotProps.value.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                <div>{{ slotProps.value.name }}</div>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="d-flex align-items-center">
                                <img :alt="slotProps.option.name" :src="this.Store.host + slotProps.option.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                <div>{{ slotProps.option.name }}</div>
                            </div>
                        </template>
                    </Dropdown>
                </div>

                <div v-if="edit">
                    <Dropdown
                    v-model="responsableTaskEdit"
                    :options="this.Store.users"
                    filter
                    optionLabel="name"
                    placeholder="Select a Country"
                    style="z-index: 9999 !important; height: calc(4rem + 2px);"
                    class="w-full my_form">
                        <template #value="slotProps">
                            <div class="d-flex align-items-center">
                                <img :alt="slotProps.value.name" :src="this.Store.host + slotProps.value.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                <div>{{ slotProps.value.name }}</div>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="d-flex align-items-center">
                                <img :alt="slotProps.option.name" :src="this.Store.host + slotProps.option.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                <div>{{ slotProps.option.name }}</div>
                            </div>
                        </template>
                    </Dropdown>
                </div>
            </template>
            <template #footer>
                <div class="d-flex justify-content-end mt-4">
                  <button class="btn Color-Docs font-docs mr-3" style="border: 1px solid #F1860B">
                        Cancelar
                  </button>
                  <button v-if="!edit" class="btn background-Docs text-white font-docs" @click="ChangeResponsable()">
                    Salvar
                  </button>
                  <button v-if="edit" class="btn background-Docs text-white font-docs" @click="EditChangeResponsable()">
                    Salvar
                  </button>
                </div>
            </template>
        </ModalChoseUser>
    </div>
</template>
<style>
</style>
