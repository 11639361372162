<script>
import { AxiosAPI } from '@/axios';
import ModalBigger from './ModalBigger.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import TreeSelectTable from './TreeSelectTable.vue';
import { useMainStore } from '../../../stores/index';
import routherPath from '@/router/routerpath';
export default {
  components: {
    ModalBigger,
    ArgonInput,
    // eslint-disable-next-line vue/no-unused-components
    TreeSelectTable
  },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },
  data () {
    return {
      name: '',
      email: '',
      password: '',
      password_two: '',
      Departamentos: [],
      cpf: '',
      formattedCpf: '',
      tipo: [],
      matricula: '',
      cargo: '',
      birth: '',
      permissions: [],
      permissionsSelect: [{ id: 1, title: 'AGUARDE...' }],
      gender: 'SELECIONE',
      loading: true,
      genderSelect: [{ id: 0, name: 'SELECIONE' }, { id: 1, name: 'MASCULINO' }, { id: 2, name: 'FEMININO' }, { id: 3, name: 'OUTROS' }],
      progress: 0,
      UserType: 0,
      ListToAdd: [],
      AddUsers: [],
      ownerDepartament: [],
      pathServidor: false,
      loadingLink: true
    };
  },

  methods: {
    openModalSerividor (value) {
      this.pathServidor = value;
      this.openModal();
    },

    openModalSetor (value) {
      this.pathServidor = false;
      this.openModal(value);
    },

    openModal (value) {
      this.ownerDepartament = value;
      this.linkRegisterUser = '';
      this.loadingLink = true;
      this.UserType = 0;
      this.name = '';
      this.birth = '';
      this.gender = 'SELECIONE';
      this.matricula = '';
      this.cargo = '';
      this.permissions = '';
      this.cpf = '';
      this.password = '';
      this.password_two = '';
      this.Departamentos = [];
      this.Store.DepartamentosAdd = [];
      this.loading = false;
      this.Abilities();

      const departmentUserIds = new Set(this.Store.ListUsersDepartament.map(user => user.id));
      const list = this.Store.users
        .filter(user => !departmentUserIds.has(user.id))
        .map(user => ({
          ...user,
          label: user.name,
          value: user.id
        }));

      this.ListToAdd = list;

      this.$refs.viewDocument.openModal();
    },

    OpenTreeSelectTable () {
      this.$refs.TreeSelectTable.openModal();
    },

    Abilities () {
      AxiosAPI.get('/api/abilities', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then(response => {
          this.permissionsSelect = response.data.abilities;
        });
    },

    AddServidorReal () {
      this.$refs.hiddenADD.click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      const ids = [];
      this.AddUsers.forEach(element => {
        ids.push(element.id);
      });
      AxiosAPI.post('/api/departments/' + this.ownerDepartament.id + '/users',
        {
          users: ids
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then(() => {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.emitter.emit('AddServidor', 1);
            this.name = '';
            this.birth = '';
            this.gender = '';
            this.matricula = '';
            this.cargo = '';
            this.permissions = '';
            this.cpf = '';
            this.password = '';
            this.password_two = '';
            this.Departamentos = '';
            this.progress = 0;
            this.ListToAdd = [];
            this.AddUsers = [];
            this.$refs.hiddenADDCloses.click();
            this.$refs.viewDocument.closeModal();
          }, 2000);
        });
    },
    Add_Servidores () {
      this.loading = true;
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      this.name = document.getElementById('name').value;
      this.email = document.getElementById('email').value;
      this.birth = document.getElementById('birth').value;
      this.matricula = document.getElementById('matricula').value;
      this.cargo = document.getElementById('cargo').value;

      let Gender = '';
      if (this.gender === 'MASCULINO') {
        Gender = 'm';
      } else if (this.gender === 'FEMININO') {
        Gender = 'f';
      } else {
        Gender = 'x';
      }

      const image = document.getElementById('image').files[0];

      const Departaments = [];
      this.Store.DepartamentosAdd.forEach(DepartId => {
        Departaments.push(DepartId.id);
      });
      const DepartamentsJson = JSON.stringify(Departaments);

      const abilitys = [];
      this.permissions.forEach(abilitysId => {
        abilitys.push(abilitysId.id);
      });
      const abilitysJson = JSON.stringify(abilitys);

      const formData = new FormData();

      formData.append('name', this.name);
      formData.append('birth', this.birth);
      formData.append('gender', Gender);
      formData.append('registration', this.matricula);
      formData.append('role', this.cargo);
      formData.append('ability_id', abilitysJson);
      formData.append('cpf', this.formattedCpf);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('password_confirmation', this.password_two);
      formData.append('path_image', image);
      formData.append('department_id', DepartamentsJson);

      AxiosAPI.post('/api/users/', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then(() => {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.emitter.emit('Add_Servidores', 1);
            this.name = '';
            this.birth = '';
            this.gender = '';
            this.matricula = '';
            this.cargo = '';
            this.permissions = '';
            this.cpf = '';
            this.password = '';
            this.password_two = '';
            this.Departamentos = '';
            this.progress = 0;
            this.$refs.hiddenButtonCloses.click();
            this.$refs.viewDocument.closeModal();
          }, 2000);
        });
    },

    formatCpf () {
      const unformattedCpf = this.formattedCpf.replace(/\D/g, '');

      this.formattedCpf = this.formattedCpf.substring(0, 11);

      let formattedCpf = '';
      for (let i = 0; i < unformattedCpf.length; i++) {
        formattedCpf += unformattedCpf[i];
        if (i === 2 || i === 5) {
          formattedCpf += '.';
        } else if (i === 8) {
          formattedCpf += '-';
        }
      }

      this.formattedCpf = formattedCpf;
    },

    LinkRegister () {
      this.loadingLink = true;
      const abilitys = [];
      this.permissions.forEach(abilitysId => {
        abilitys.push(abilitysId.id);
      });
      const abilitysJson = JSON.stringify(abilitys);

      AxiosAPI.post('/api/users/link', {
        role: this.cargo,
        abilities: abilitysJson,
        email: this.email
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          console.log(resp);
          const url = window.location.href;
          const startIndex = url.indexOf('https://') + 8;
          const pathIndex = url.indexOf('/', startIndex);
          const result = url.substring(startIndex, pathIndex);
          this.linkRegisterUser = result + '/' + routherPath + '/cadastro_servidor/token=' + resp.data.user.token;
          this.email = '';
          this.loadingLink = false;
        });
    }

  }
};

</script>

<style>
.close {
    font-size: 2.3rem !important;
}

.p-multiselect-panel .p-component .p-ripple-disabled{
    z-index: 1000 !important;
}

.noneClass{
    display: none;
}

.my_form{
    min-height: unset;
    padding: 0.75rem 0.75rem;
    font-size: 0.875rem;
    height: calc(2.875rem + 2px);
    border-radius: 0.5rem;
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d6da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: auto;
    border-radius: 0.5rem;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
}
</style>

<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
    <div>
      <TreeSelectTable ref="TreeSelectTable"></TreeSelectTable>
        <ModalBigger ref="viewDocument" :class="{'noneClass': loading}">
            <template #header>
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                            <span>Cadastrar sevidor</span>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>
            <div v-if="UserType === 0 && pathServidor" class="d-flex justify-content-center">
              <button type="button" class="btn mr-3 font-docs Color-Docs" style="border: 1px solid #F1860B;height: 80px; font-size:19px" @click="UserType = 2;">
                        Servidor não existente
                    </button>
              <button type="button" class="btn font-docs text-white" style="height: 80px; font-size:19px;background-color:#a03b3b;" @click="UserType = 3;">
                        Via link
              </button>
            </div>
            <div v-if="UserType === 0 && !pathServidor" class="d-flex justify-content-center">
              <button type="button" class="btn mr-3 font-docs Color-Docs" style="border: 1px solid #F1860B;height: 80px; font-size:19px" @click="UserType = 2;">
                        Servidor não existente
                    </button>
              <button type="button" class="btn font-docs mr-3 text-white background-Docs" style="height: 80px; font-size:19px" @click="UserType = 1;">
                        Servidor já existente
              </button>
              <button type="button" class="btn font-docs text-white" style="height: 80px; font-size:19px; background-color:#a03b3b;" @click="UserType = 3;">
                        Via link
              </button>
            </div>
            <div v-if="UserType === 1" style="height: 200px">
              <div>
                <treeselects v-model="AddUsers" :disable-branch-nodes="true" value-format="object" :flat="true" :multiple="true"
                  :options="ListToAdd" placeholder="Busque pelo nome"/>

                <div v-if="AddUsers.length >= 1">
                  <DataTable :value='AddUsers' :responsive="true">
                    <template #header>
                        <div class="d-flex flex-wrap align-items-center justify-content-between gap-2">
                        <div><span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">Selecionados</span></div>
                        </div>
                    </template>
                    <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 20rem; font-size: 16px !important'>
                      <template #body="slotProps">
                        <div v-if="!this.Store.mobile" class="d-flex">
                          <div>
                            <img :src="this.Store.host + slotProps.data.path_image"
                              style="widows: 40px; height: 40px; border-radius: 50%;" />
                          </div>
                          <div class="d-grid ml-2">
                            <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                            <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                          </div>
                        </div>
                        <div v-if="this.Store.mobile">
                          <div  class="d-flex justify-content-end">
                            <img :src="this.Store.host + slotProps.data.path_image"
                                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                          </div>
                          <div class="d-grid ml-2 text-end">
                            <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                            <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                          </div>
                        </div>
                      </template>
                    </Column>
                    <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
            <div v-if="UserType === 2">
              <div class="mt-3">
                    <label for="formFile" class="label-docs">Nome completo<span style="color:red">*</span></label>
                    <argon-input id="name" v-model="name_departament" type="text" placeholder="Nome do servidor" name="name" size="lg" />
                </div>

                <div class="mt-2">
                    <label for="formFile" class="label-docs">E-mail <span style="color:red">*</span></label>
                    <argon-input id="email" v-model="email" type="email" placeholder="servidor@hotmail.com" name="email" size="lg" />
                </div>

                <div class='row'>
                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="label-docs">Data de nascimento <span style="color:red">*</span></label>
                    <argon-input id="birth" v-model="birth" type="date" placeholder="dd/mm/aaaa" name="name" size="lg" />
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="form-label text-sm">Cpf</label>
                    <input class="my_form" v-model="formattedCpf" placeholder="xxx.xxx.xxx-xx" @input="formatCpf()" maxlength="14">
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="label-docs">Matricula <span style="color:red">*</span></label>
                    <argon-input id="matricula" v-model="matricula" type="text" placeholder="1234" name="name" size="lg" />
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="label-docs">Cargo <span style="color:red">*</span></label>
                    <argon-input id="cargo" v-model="cargo" type="text" placeholder="Secretário de ..." name="name" size="lg" />
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="label-docs">Foto de perfil <span style="color:red">*</span></label>
                    <argon-input id="image" type="file" placeholder="IMAGEM" name="name" size="lg" />
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="form-label text-sm">GÊNERO</label>
                    <select id='choices-category' v-model='gender' class='my_form' name='choices-category'>
                        <option v-for='option in genderSelect' :key='option.name' :value='option.name'>
                          {{ option.name }}
                        </option>
                      </select>
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="form-label text-sm">INFORME AS PERMISSÕES</label>
                    <MultiSelectPrime id="choices-category" v-model="permissions" display="chip" :options="permissionsSelect" option-label="title" placeholder="Selecione as perimissões"
                    class="form-control w-full md:w-20rem" style="height: calc(3.5rem + 2px) !important;"/>
                </div>
              </div>
                <div class="row">
                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="label-docs">Senha <span style="color:red">*</span></label>
                    <input class="my_form" type="password" v-model="password" placeholder="*********">
                </div>

                <div class="col-sm-6 mt-2">
                  <label for="formFile" class="label-docs">Repita a senha<span style="color:red">*</span></label>
                  <input class="my_form" type="password" v-model="password_two" placeholder="*********">
                </div>

                </div>
                <div class="mt-2">
                  <label for="formFile" class="form-label text-sm">SELECIONAR DEPARTAMENTOS E SUB DEPARTAMENTOS: (CASO JÁ EXISTA O DEPARTAMENTO)</label>
                  <button class="ml-2 mt-2 btn background-Docs text-white" @click="OpenTreeSelectTable"> + </button>
                </div>

                <div class="d-flex">
                    <div v-for='departamendosADD in this.Store.DepartamentosAdd' :key='departamendosADD.id'  class="d-flex justify-content-center">
                        <Tag class="d-flex ml-2" style='border: 1px solid rgb(6 70 167);background-color: rgb(6 48 167 / 10%);color: rgb(6 54 167);'>
                            <div class='flex align-items-center gap-2'>
                                <font-awesome-icon class="mr-1" icon="fa fa-building" />
                                <span class='text-base text-uppercase'>{{ departamendosADD.title}}</span>
                            </div>
                        </Tag>
                    </div>
                </div>
              </div>
              <div v-if="UserType === 3 && loadingLink">
                <div class='row'>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="label-docs">Cargo <span style="color:red">*</span></label>
                    <input class="my_form" id="cargo" v-model="cargo" type="text" placeholder="Secretário de ..." name="name" size="lg" />
                </div>

                <div class="col-sm-6 mt-2">
                    <label for="formFile" class="form-label text-sm">INFORME AS PERMISSÕES<span style="color:red">*</span></label>
                    <MultiSelectPrime id="choices-category" v-model="permissions" display="chip" :options="permissionsSelect" option-label="title" placeholder="Selecione as perimissões"
                    class="form-control w-full md:w-20rem" style="height: calc(3.5rem + 2px) !important;"/>
                </div>

                <div class="mt-2">
                    <label for="formFile" class="label-docs">e-mail </label>
                    <input class="my_form" id="cargo" v-model="email" type="email" placeholder="xxxxxx@xxxxx.com" name="name" size="lg" />
                    <small class="font-docs" style="color:red">caso informado, um e-mail irá ser enviado com link do cadastro</small>
                </div>
              </div>
            </div>
            <div v-if="UserType === 3 && !loadingLink">
                <div class="d-flex justify-content-center text-center">
                    <span class="font-docs">Link para cadastro gerado, envie para o novo usuário</span>
                </div>
                <div class="d-flex justify-content-center text-center">
                    <span class="font-docs"><b>{{ linkRegisterUser }}</b></span>
                </div>
              </div>
            </template>

            <template #footer>
              <div v-if="UserType === 1" class="d-flex justify-content-end">
                    <button type="button" class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="AddServidorReal();">
                        Adicionar sevidor
                    </button>
                </div>
                <div v-if="UserType === 2" class="d-flex justify-content-end">
                    <button type="button" class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="Add_Servidores();">
                        Confirmar cadastro
                    </button>
                </div>
                <div v-if="UserType === 3 && loadingLink" class="d-flex justify-content-end">
                    <button type="button" class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button type="button" class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="LinkRegister();">
                        Criar Link
                    </button>
                </div>
            </template>
        </ModalBigger>

        <button type="button" ref="hiddenADD" class="btn btn-primary d-none" data-toggle="modal" data-target="#LoadingAddUser"></button>
        <div class="modal fade" id="LoadingAddUser" tabindex="-1" role="dialog" aria-labelledby="LoadingAddUser" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
                          <button type="button" ref="hiddenADDCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                          <div class="modal-body">
                            <span class="font-docs text-white d-flex justify-content-center">Adicionando usuário, Por favor, aguarde...</span>
                            <div class="progress" style="height: 30px;">
                              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
                              animation: progress-bar-stripes 1s linear infinite !important;
                              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
                              background-size: 0.5rem 1rem;
                              transition: width .6s ease;
                              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

        <button type="button" ref="hiddenButtons" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loading"></button>
        <div class="modal fade" id="Loading" tabindex="-1" role="dialog" aria-labelledby="Loading" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
                          <button type="button" ref="hiddenButtonCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                          <div class="modal-body">
                            <span class="font-docs text-white d-flex justify-content-center">Criando usuário, Por favor, aguarde...</span>
                            <div class="progress" style="height: 30px;">
                              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
                              animation: progress-bar-stripes 1s linear infinite !important;
                              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
                              background-size: 0.5rem 1rem;
                              transition: width .6s ease;
                              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    </div>
</template>
