<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../../stores/index';
import ProgressBarDocsEdit from './ProgressBarEdit.vue';
export default {
  name: 'DashboardDefault',
  components: { ProgressBarDocsEdit },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      LoadingPreview: false,
      expandedRows: {},
      tableKeys: [],
      DataUsers: [],
      Signers: [],
      attachment_signers: [],
      tableNoFormat: [],
      checked: false,
      loading: false
    };
  },
  mounted () {
    if (this.Store.EditDocumentSigners === false) {
      this.GetServidoresSolo();
    } else {
      this.tableKeys = this.Store.SignersDataEdit.tableKeys;
      this.SignersData();
    }
  },
  computed: {
  },
  watch: {
    DataUsers: function (newValue) {
      const Signers = [];
      this.tableKeys.forEach(element => {
        element.children.forEach(users => {
          newValue.forEach(values => {
            if (users.value === values) {
              Signers.push(users);
            }
          });
        });
      });
      this.Signers = Signers;
      this.Store.SignersDataEdit.DataUsers = this.DataUsers;
      this.Store.SignersDataEdit.tableKeys = this.tableKeys;
    }
  },
  methods: {
    async nextProgress () {
      this.LoadingPreview = true;
      const requiredSigners = [];
      const signers = [];
      const AttachSigner = [];
      const AllSigners = [];
      this.Signers.forEach(element => {
        if (element.signer === true) {
          requiredSigners.push(element.toSendID);
        }
        if (element.signer === false) {
          signers.push(element.toSendID);
        }
      });

      this.Signers.forEach(element => {
        if (element.attachment_signer === true) {
          AttachSigner.push(element.toSendID);
        }
      });

      AllSigners.push(...requiredSigners, ...signers);

      this.Store.documentDataEdit.required_signers = requiredSigners;
      this.Store.documentDataEdit.signers = AllSigners;
      this.Store.SignersDataEdit.Signers = this.Signers;
      this.Store.documentDataEdit.attachment_signer = AttachSigner;
      this.Store.SignersDataEdit.DataUsers = this.DataUsers;
      const data = {
        title: 'Documento teste',
        subject: this.Store.documentDataEdit.subject,
        body: this.Store.documentDataEdit.body,
        document_type_id: this.Store.documentDataEdit.typeDoc,
        to_users: this.Store.documentDataEdit.to_users,
        to_departments: this.Store.documentDataEdit.to_departments,
        user_id: this.Store.documentDataEdit.user_id,
        department_id: this.Store.documentDataEdit.department_id
      };
      await AxiosAPI.post('/api/documents/preview/', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          this.Store.pdf64 = resp.data.file;
          this.LoadingPreview = false;
        });
      this.Store.progressEdit += 1;
    },
    backProgress () {
      this.Store.progressEdit -= 1;
    },

    async GetServidoresSolo () {
      this.Signers = [];
      await AxiosAPI.get('/api/departments/group/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          const data = [];
          for (let i = 0; i < resp.data.departments.length; i++) {
            const obj = resp.data.departments[i];

            obj.label = obj.title;
            delete obj.title;

            obj.key = obj.value;
            delete obj.value;

            obj.children = obj.users;
            delete obj.users;
            for (let i = 0; i < obj.children.length; i++) {
              const childrens = obj.children[i];
              childrens.toSendID = childrens.id;
              delete childrens.id;
              childrens.id = childrens.value;

              childrens.label = childrens.name;
              delete childrens.name;

              childrens.signer = false;
              childrens.attachment_signer = false;
              childrens.send = false;
            }
            data.push(obj);
          }
          this.tableKeys = data;
          this.Store.SignersDataEdit.tableKeys = this.tableKeys;
          this.SignersData();
        });
    },

    SignersData () {
      const signersData = [];
      if (this.Store.EditDocumentSigners === false) {
        this.tableKeys = this.Store.SignersDataEdit.tableKeys;

        this.Store.SignersDataEdit.tableKeys.forEach(TabChild => {
          TabChild.children.forEach(usersValues => {
            this.Store.SignersDataEdit.Signers.forEach(users => {
              if (usersValues.toSendID === users.user_id) {
                if (users.clicksign_status !== '0') {
                  usersValues.blockRemove = true;
                } else {
                  usersValues.blockRemove = false;
                }
                if (users.required === true) {
                  usersValues.signer = true;
                }
                signersData.push(usersValues.value);
              }
            });
          });
        });
        this.DataUsers = signersData;
        this.Store.EditDocumentSigners = true;
      } else {
        this.Store.SignersDataEdit.tableKeys.forEach(TabChild => {
          TabChild.children.forEach(usersValues => {
            this.Store.SignersDataEdit.Signers.forEach(users => {
              if (usersValues.toSendID === users.user_id) {
                if (users.clicksign_status !== '0') {
                  usersValues.blockRemove = true;
                } else {
                  usersValues.blockRemove = false;
                }
                if (users.required === true) {
                  usersValues.signer = true;
                }
              }
            });
          });
        });
        this.DataUsers = this.Store.SignersDataEdit.DataUsers;
      }
    },

    toggleSigner (row) {
      row.signer = row.signer !== true;
    },
    toggleAttachmentSigner (row) {
      row.attachment_signer = !row.attachment_signer;
    },
    toggleInbox (row) {
      row.inbox = row.inbox !== true;
    },
    removeLine (values) {
      this.DataUsers = this.DataUsers.filter(item => item !== values.value);
    }
  }
};
</script>
<style>
.drop-zone--over {
  background-color: #d4edf7;
}
.form-check-input:checked{
  border-color: #F1860B !important;
  background-color: #F1860B !important;
  padding-bottom: 18px;
}
.p-datatable-header{
    background-color: #E9ECEF !important;
  }
</style>

<template>
  <div class="container">
    <ProgressBarDocsEdit />
    <div v-if="LoadingPreview" class="row d-flex justify-content-center align-items-center mt-5">
      <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:50%"></ProgressBar>
      <span class="d-flex justify-content-center align-items-center font-docs">carregando, previsualização...</span>
    </div>
    <div v-if="this.Store.blockBySigner" class="d-flex justify-content-between align-items-center" style="background-color:#B6D4FE;height: 50px;">
          <div>
            <font-awesome-icon :icon="['fas', 'circle-info']" class="ml-2 mr-2" style="color:#084298; font-size: 15px;" />
            <span class="font-docs" style="color: #084298"> <b> Você pode adicionar pessoas, porém, apenas os que não assinaram ainda podem ser removidos.</b> </span>
          </div>
        </div>
    <div v-if="!LoadingPreview" class="w-100 mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black"><b>Assinaturas</b></h4>
        </div>
    </div>
    <div v-if="!LoadingPreview">
      <hr>
      <div>
        <div class="mt-4 ml-2">
           <span> Assinante(s) <span style="color: red;">*</span></span>
        </div>
       <treeselects v-model="DataUsers" :disable-branch-nodes="true" :flat="true" placeholder="Busque por nome ou nome do departamento"  :multiple="true" :options="tableKeys" ></treeselects>
            <DataTable :value="Signers" :responsive="true" :loading='loading'>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.Store.mobile" class="d-flex">
                    <div>
                      <img :src="this.Store.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.Store.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.Store.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column field='departments' header='Setor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <span v-if="slotProps.data.departments.length <= 0" class="font-docs font-weight-bold">Departamento não definido</span>
                  <span v-if="slotProps.data.departments.length >= 1" class="font-docs font-weight-bold">{{slotProps.data.departments[0].title}}</span>
                </template>
              </Column>
              <Column header='Assinar anexo' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch"
                      :id="'switch-attachment-' + slotProps.data.id"
                      v-model="slotProps.data.attachment_signer"
                      @click="toggleAttachmentSigner(slotProps.data)"
                      :disabled="slotProps.data.profile === 'Leitor' || this.Store.documentDataEdit.attachments.length <= 0">
                  </div>
                </template>
              </Column>
               <Column header='Assinatura obrigatória' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div class="form-check form-switch" v-if="!slotProps.data.blockRemove">
                    <input class="form-check-input" type="checkbox" role="switch" :id="'switch-' + slotProps.data.signer" v-model="slotProps.data.signer" @click="toggleSigner(slotProps.data)" :disabled="slotProps.data.profile === 'Leitor' || !slotProps.data.attachment_signer">
                  </div>
                  <div class="form-check form-switch" v-if="slotProps.data.blockRemove">
                    <input class="form-check-input" type="checkbox" role="switch" :id="'switch-' + slotProps.data.signer" v-model="slotProps.data.signer" @click="toggleSigner(slotProps.data)" disabled>
                  </div>
                </template>
              </Column>
             <Column class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 2rem; font-size: 16px !important;'>
                <template #body="slotProps">
                  <div v-if="!slotProps.data.blockRemove" class="d-flex justify-content-end">
                  <font-awesome-icon :icon="['fas', 'trash-can']" style="cursor: pointer;font-size: 16px;" @click="removeLine(slotProps.data)"/>
                  </div>
                </template>
              </Column>
            </DataTable>
      </div>
      <div class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
      </div>
      </div>
</div>
</template>
