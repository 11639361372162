<script>
import { useMainStore, useResponseStore, useEventStore } from '../../../stores/index';
import ProgressBarDocs from './ProgressBarResp.vue';
export default {
  name: 'DashboardDefault',
  components: { ProgressBarDocs },
  setup () {
    const Main = useMainStore();
    const Store = useResponseStore();
    const Events = useEventStore();
    return {
      StoreMain: Main,
      StoreResp: Store,
      Events
    };
  },
  data () {
    return {
      files: [],
      isDragging: false,
      attachments: [],
      tack: [],
      signerAttach: null
    };
  },
  mounted () {
    if (this.StoreResp.documentData.attachments !== undefined) {
      this.attachments = [];
      this.tack = [];
      this.tack.push(...this.StoreResp.documentData.attachments);
      for (let i = 0; i < this.tack.length; i++) {
        this.attachments.push({ name: this.tack[i].name });
      }
    }
    if (this.StoreResp.documentData.signerAttach === null) {
      this.signerAttach = false;
    } else if (this.StoreResp.documentData.signerAttach === 0) {
      this.signerAttach = false;
    } else if (this.StoreResp.documentData.signerAttach === 1) {
      this.signerAttach = true;
    }
  },
  watch: {
  },
  methods: {
    nextProgress () {
      this.StoreResp.progress += 1;
      if (this.signerAttach === false) {
        this.StoreResp.documentData.signerAttach = 0;
      } else if (this.signerAttach === true) {
        this.StoreResp.documentData.signerAttach = 1;
      } else {
        this.StoreResp.documentData.signerAttach = 0;
      }
    },
    backProgress () {
      this.StoreResp.progress -= 1;
    },
    openFileInput () {
      this.$refs.fileInput.click();
      this.isDragging = false;
    },
    addAttachment (event) {
      const files = Array.from(event.target.files);
      const newFiles = files.filter(file =>
        !this.tack.some(existingFile => existingFile.name === file.name)
      );

      this.attachments.push(...newFiles.map(file => ({ name: file.name })));
      this.tack.push(...newFiles);

      this.StoreResp.documentData.attachments = this.tack;
    },
    removeAttachment (index) {
      this.attachments.splice(index, 1);
      this.tack.splice(index, 1);
      this.StoreResp.documentData.attachments = this.tack;
      if (this.attachments.length <= 0) {
        this.signerAttach = false;
      }
    },
    handleDrop (event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.handleFiles(files);
      this.isDragging = false;
    },
    handleDragEnter () {
      this.isDragging = true;
    },
    handleDragLeave () {
      this.isDragging = false;
    },
    handleFileInput () {
      const files = this.$refs.fileInput.files;
      this.handleFiles(files);
      this.isDragging = false;
    },
    handleFiles (files) {
      this.files = [];
      for (let i = 0; i < files.length; i++) {
        this.attachments.push({ name: files[i].name });
        this.tack.push(...files);
      }
      this.StoreResp.documentData.attachments = this.tack;
    }
  }
};
</script>
<style>
.drop-zone--over {
  background-color: #d4edf7;
}

</style>
<template>
  <div class="container">
    <ProgressBarDocs />
    <div  v-if="!this.StoreMain.mobile" class="container mt-3">
      <div class="row">
        <h4 class="font-docs text-docs-black ml-1"><b>Anexos</b></h4>
        <hr>
      </div>

    <div class='row justify-content-center' style="margin-bottom: 5rem" @dragover.prevent="handleDragEnter" @drop="handleDrop" @dragleave="handleDragLeave">
        <div class="w-95" :class="{ 'drop-zone--over': isDragging }" style="border: 2px dashed #F1860B; height: 270px !important; gap: 20px; padding: 32px 0px 32px 0px; display: grid; cursor: pointer;"
        @click='openFileInput'>
          <div class="d-flex justify-content-center">
            <img src="../../../assets/img/inboxIcon.png" style="width: 48px; height: 48px"/>
          </div>
          <div class="d-flex justify-content-center">
            <span class="font-docs text-docs-black text-bold">Clique ou arraste seu arquivo até aqui.</span>
          </div>
          <div class="text-center">
            <div> <span class="font-docs" style="color: #6C757D">Formatos aceitos: Word, PDF, JPG, PNG. <br/> Tamanho máximo do arquivo: 10MB.</span> </div>
            <div>
              <input type='file' ref='fileInput' @change='addAttachment' accept='.doc,.docx,.odt,.pdf,.png,.jpg,.svg' style='display: none' multiple/>
              <button class="btn mt-3 font-docs text-white background-Docs"><img class="mr-2" src="../../../assets/img/Union.png">Adicionar arquivo</button> </div>
          </div>
      </div>
    </div>

    <div class='ml-3 mt-2 mb-4'>
        <div class='mt-3'>
          <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
            style='height: 40px;align-items: center;'>
            <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
              {{attachment.name}}
                <button type='button' @click='removeAttachment(index)'
                  style='border: none;'>
                  <i class="fa fa-trash-o ml-2" aria-hidden="true" style="color: #212529"></i>
                </button>
            </span>
          </div>
      </div>
      <div v-if="!this.StoreMain.mobile" class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
      </div>
    </div>

    <div  v-if="this.StoreMain.mobile">
      <div class="row">
        <h4 class="font-docs text-docs-black ml-1 mt-4 mb-2"><b>Anexos</b></h4>
        <hr>
      </div>
    <div class='row justify-content-center mt-1' style="margin-bottom: 5rem">
        <div class="w-95" :class="{ 'drop-zone--over': isDragging }" style="border: 3px dashed #F1860B; height: 270px !important; gap: 20px; padding: 32px 0px 32px 0px; display: grid;">
          <div class="d-flex justify-content-center">
            <img src="../../../assets/img/inboxIcon.png" style="width: 38px; height: 38px"/>
          </div>
          <div class="d-flex justify-content-center">
            <span class="font-docs text-docs-black text-bold" style="font-size: 14px">Clique ou arraste seu arquivo até aqui.</span>
          </div>
          <div class="text-center">
            <div> <span class="font-docs" style="color: #6C757D; font-size: 13px">Formatos aceitos: Word, PDF, JPG, PNG. <br/> Tamanho máximo do arquivo: 10MB.</span> </div>
            <div>
              <input type='file' ref='fileInput' @change='addAttachment' style='display: none' multiple/>
              <button class="btn mt-3 font-docs text-white background-Docs" @click='openFileInput'><img class="mr-2" src="../../../assets/img/Union.png">Adicionar arquivo</button> </div>
          </div>
      </div>
      <div class='ml-3 mt-2 mb-4'>
        <div class='mt-3'>
          <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
            style='height: 40px;align-items: center;'>
            <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
              {{ attachment.name.substring(0, 15) }} {{ attachment.length > 15 ? '...' : '...' }}
                <button type='button' @click='removeAttachment(index)'
                  style='border: none; background-color: white'>
                  <i class="fa fa-trash-o ml-2" aria-hidden="true" style="color: #212529"></i>
                </button>
            </span>
          </div>
      </div>
    </div>
    </div>
    <div v-if="this.StoreMain.mobile" class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
        </div>
  </div>

</template>
