<script>
import { useMainStore } from '../../../stores/index';
import { AxiosAPI } from '@/axios';
export default {
  name: 'TodoListUpdated',
  components: { },
  props: {
    ExistCheckList: {
      type: Object
    },

    TaskID: {
      type: Number
    }
  },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  computed: {
    ExistCheckLists () {
      if (this.ExistCheckList) {
        return true;
      } else {
        return false;
      }
    }
  },
  data () {
    return {
      lists: this.ExistCheckList ? this.ExistCheckList : [],
      newItem: {
        description: '',
        is_checked: false,
        isEditingItem: false
      },
      loading: false
    };
  },

  methods: {
    addList () {
      this.loading = true;
      const Items = JSON.stringify([{ description: 'Item 1', is_checked: false, isEditingItem: false }]);
      const formData = new FormData();
      formData.append('title', 'Checklist');
      formData.append('isEditingText', false);
      formData.append('items', Items);
      formData.append('addItemInput', false);

      AxiosAPI.post('/api/tasks/' + this.TaskID + '/checklists/', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then((resp) => {
        const newChecklistId = resp.data.checklist.id;
        this.lists.push({
          id: newChecklistId,
          title: 'CheckList',
          isEditingText: false,
          items: [
            {
              description: '',
              is_checked: false,
              isEditingItem: true
            }
          ],
          addItemInput: false
        });
        this.loading = false;
        this.emitter.emit('KabanUpdated');
      });
    },

    addItem (listIndex) {
      this.lists[listIndex].items.push(this.newItem);
      const checklistItems = JSON.stringify(this.lists[listIndex].items);
      const formData = new FormData();
      formData.append('title', this.lists[listIndex].title);
      formData.append('items', checklistItems);
      formData.append('_method', 'PUT');
      AxiosAPI.post('/api/tasks/' + this.TaskID + '/checklists/' + this.lists[listIndex].id, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.newItem = {
          description: '',
          isEditingItem: false,
          is_checked: false
        };
        this.emitter.emit('KabanUpdated');
      });
    },

    editTitle (index) {
      this.lists[index].isEditingText = true;
    },

    saveTitle (index, checklistID) {
      this.lists[index].isEditingText = false;
      const checklistItems = JSON.stringify(this.lists[index].items);
      const formData = new FormData();
      formData.append('title', this.lists[index].title);
      formData.append('items', checklistItems);
      formData.append('_method', 'PUT');
      AxiosAPI.post('/api/tasks/' + this.TaskID + '/checklists/' + checklistID, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.emitter.emit('KabanUpdated');
      });
    },

    editItem (listIndex, itemIndex) {
      this.lists[listIndex].items[itemIndex].isEditingItem = true;
    },
    saveItemTitle (listIndex, itemIndex, checklistID) {
      this.lists[listIndex].items[itemIndex].isEditingItem = false;

      const checklistItems = JSON.stringify(this.lists[listIndex].items);
      const formData = new FormData();
      formData.append('title', this.lists[listIndex].title);
      formData.append('items', checklistItems);
      formData.append('_method', 'PUT');
      AxiosAPI.post('/api/tasks/' + this.TaskID + '/checklists/' + checklistID, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then((resp) => {
        this.emitter.emit('KabanUpdated');
      });
    },

    CheckInput (listIndex, itemIndex, checklistID) {
      setTimeout(() => {
        const checklistItems = JSON.stringify(this.lists[listIndex].items);
        const formData = new FormData();
        formData.append('title', this.lists[listIndex].title);
        formData.append('items', checklistItems);
        formData.append('_method', 'PUT');
        AxiosAPI.post('/api/tasks/' + this.TaskID + '/checklists/' + checklistID, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then(() => {
          this.emitter.emit('KabanUpdated');
        });
      }, 500);
    },

    removeItem (listIndex, itemIndex, checklistID) {
      this.lists[listIndex].items.splice(itemIndex, 1);

      const checklistItems = JSON.stringify(this.lists[listIndex].items);
      const formData = new FormData();
      formData.append('title', this.lists[listIndex].title);
      formData.append('items', checklistItems);
      formData.append('_method', 'PUT');
      AxiosAPI.post('/api/tasks/' + this.TaskID + '/checklists/' + checklistID, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.emitter.emit('KabanUpdated');
      });
    },

    removeList (checklistID) {
      this.lists = this.lists.filter(list => list.id !== checklistID);
      AxiosAPI.delete('/api/tasks/' + this.TaskID + '/checklists/' + checklistID, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.emitter.emit('KabanUpdated');
      });
    },

    calculateProgress (list) {
      if (list.items.length <= 0) {
        return 0;
      } else if (list.items.length === undefined) {
        return 0;
      } else {
        const totalItems = list.items.length;
        const completedItems = list.items.filter(item => item.is_checked).length;
        return totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;
      }
    },

    ItemsChecked (list) {
      if (list.items.length <= 0) {
        return 0;
      } else if (list.items.length === undefined) {
        return 0;
      } else {
        const completedItems = list.items.filter(item => item.is_checked).length;
        return completedItems;
      }
    }
  }
};
</script>
<style>
.CheckBox-docs {
    border-radius: 5px !important;
    border-color: #F1860B !important;
}
</style>
<style scoped>
.progress-bar {
    background-color: #F1860B !important;
    border-radius: 4px !important;
    height: 16px !important;
}
.progress {
    border-radius: 4px !important;
    height: 16px !important;
}
</style>
<template>
    <div>
        <div v-for="(list, index) in lists" :key="index" class="mt-3">
            <div class="d-flex justify-content-between">
              <div class="w-100">
                <i class="fa-regular fa-rectangle-list mr-2" style="font-size: 25px"></i>
                <span v-if="!list.isEditingText" class="font-docs" style="cursor:pointer" @click="editTitle(index)">{{ list.title }}</span>

                <input
                    v-if="list.isEditingText"
                    v-model="list.title"
                    class="font-docs w-90"
                    @blur="saveTitle(index, list.id)"
                    @keyup.enter="saveTitle(index, list.id)"
                    type="text"
                    style="font-size: 16px; border: none; outline: none; background: transparent;"
                />
                </div>

                <i class="fa-solid fa-trash Color-Docs mr-3" style="font-size: 20px; cursor:pointer" @click="removeList(list.id)"></i>
            </div>

            <div class="progress mt-2">
                <div class="progress-bar" role="progressbar" :style="'width:'+ calculateProgress(list) +'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="font-docs" style="color: #465668">Progresso</div>
                <div class="font-docs" style="color: #465668"> {{ ItemsChecked(list) }}/{{ list.items.length }}</div>
            </div>

            <div class="w-100">
              <input v-if="list.addItemInput" type="text" v-model="newItem.description" placeholder="Adicionar item"  class="w-100 my_form" @keyup.enter="addItem(index); list.addItemInput = false" />
            </div>

            <div v-for="(item, itemIndex) in list.items" :key="itemIndex" class="mt-2">
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                        <Checkbox v-model="item.is_checked"
                        class="border-color"
                        :inputClass="{'background-Docs CheckBox-docs': item.is_checked}"
                        @click="CheckInput(index, itemIndex, list.id)"
                        binary/>
                        <span v-if="!item.isEditingItem && !item.is_checked" class="font-docs ml-2" @click="editItem(index, itemIndex)">
                            {{ item.description }}
                        </span>

                        <span v-if="!item.isEditingItem && item.is_checked" class="font-docs ml-2" @click="editItem(index, itemIndex)">
                            <s>{{ item.description }}</s>
                        </span>

                        <input
                            v-if="item.isEditingItem"
                            v-model="item.description"
                            @blur="saveItemTitle(index, itemIndex, list.id)"
                            @keyup.enter="saveItemTitle(index, itemIndex, list.id)"
                            placeholder="Primeiro item"
                            type="text"
                            class="font-docs ml-2 w-90"
                            style="font-size: 16px; border: none; outline: none; background: transparent;"
                        />
                        </div>
                    <i class="fa-solid fa-trash Color-Docs mr-3" style="font-size: 20px; cursor:pointer" @click="removeItem(index, itemIndex, list.id)"></i>
                </div>
            </div>

            <div class="d-flex align-items-center mt-2" style="cursor: pointer" @click="list.addItemInput = true">
                <i class="fa-solid fa-plus mr-2 Color-Docs"></i>
                <span class="font-docs Color-Docs">Adicionar item</span>
            </div>
        </div>

        <div class="d-flex align-items-center mt-3">
            <button v-if="!loading" class="btn Color-Docs font-docs" style="border: 1px solid #F1860B;cursor: pointer" @click="addList">
                Adicionar checklist
            </button>
            <button v-else class="btn Color-Docs font-docs" style="border: 1px solid #F1860B">
                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 15px"></i>
            </button>
        </div>
    </div>
</template>
<style>
</style>
