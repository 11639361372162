<script>
import routerpath from '../../../router/routerpath';
import { useMainStore, useResponseStore, useEventStore } from '../../../stores/index';
import ProgressBarDocs from './ProgressBarResp.vue';
import { AxiosAPI } from '@/axios';
import ViewrMobileResp from '../Modals/ViewrMobileResp.vue';
export default {
  name: 'DashboardDefault',
  components: { ViewrMobileResp, ProgressBarDocs },
  setup () {
    const Main = useMainStore();
    const StoreMain = useResponseStore();
    const Events = useEventStore();
    return {
      StoreMain: Main,
      StoreResp: StoreMain,
      Events
    };
  },
  data () {
    return {
      attachments: [],
      loadignAttachment: false,
      progress: 0,
      urgency: false
    };
  },
  mounted () {
    this.AttachmentView();
  },
  watch: {
  },
  methods: {
    async nextProgress () {
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButton.click();
      const formData = new FormData();
      formData.append('title', 'Documento teste Resposta');
      formData.append('subject', this.StoreResp.documentData.subject);
      formData.append('document_id', this.StoreResp.documentData.document_id);
      formData.append('user_id', this.StoreResp.documentData.user_id);
      formData.append('document_type_id', this.StoreResp.documentData.typeDoc);
      formData.append('body', this.StoreResp.documentData.body);
      formData.append('department_id', this.StoreResp.documentData.department_id);
      for (let i = 0; i < this.StoreResp.documentData.attachments.length; i++) {
        formData.append('attachments[]', this.StoreResp.documentData.attachments[i]);
      }
      formData.append('attachment_signers', this.StoreResp.documentData.attachment_signer);
      formData.append('attachments_sign', this.StoreResp.documentData.signerAttach);
      formData.append('status_sign', 0);
      if (this.StoreResp.documentData.to_users !== undefined) {
        formData.append('to_users', this.StoreResp.documentData.to_users);
      }
      if (this.StoreResp.documentData.inbox !== undefined) {
        formData.append('inbox', this.StoreResp.documentData.inbox);
      }
      if (this.StoreResp.documentData.to_departments !== undefined) {
        formData.append('to_departments', this.StoreResp.documentData.to_departments);
      }

      if (this.urgency) {
        formData.append('urgency', 1);
      } else {
        formData.append('urgency', 0);
      }

      formData.append('required_signers', this.StoreResp.documentData.required_signers);
      formData.append('signers', this.StoreResp.documentData.signers);

      await AxiosAPI.post('/api/documents/', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.StoreMain.accessToken
        }
      }).then((resp) => {
        if (resp.status === 200) {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.StoreResp.SendToData = [];
            this.StoreResp.FirstIndividuallySend = [];
            this.StoreResp.SecondIndividuallySend = [];
            this.StoreResp.tableNoFormat = [];
            this.StoreResp.SendTwo = false;
            this.StoreResp.SecondSend = false;
            this.StoreResp.tableKeys = [];
            this.StoreResp.SignersData = [];
            this.StoreResp.documentData = [];
            this.StoreResp.documentData = { typeDoc: 0, attachments: [], subject: '', body: '', status_sign: 0, user_id: 0, to_users: [], to_departments: [] };
            this.$refs.hiddenButtonClose.click();
            this.StoreResp.progress = 1;
            const originalString = this.StoreResp.oldDocProtocol;
            const urlSafeString = encodeURIComponent(originalString);
            if (this.$route.name === 'DespacharRecebido') {
              this.$router.push('/' + routerpath + '/Recebidos/' + urlSafeString);
            }
            if (this.$route.name === 'DespacharEnviados') {
              this.$router.push('/' + routerpath + '/Enviados/' + urlSafeString);
            }
            if (this.$route.name === 'DespacharAssinaturas') {
              this.$router.push('/' + routerpath + '/Assinaturas/' + urlSafeString);
            }
            if (this.$route.name === 'DespacharFavoritos') {
              this.$router.push('/' + routerpath + '/Favoritos/' + urlSafeString);
            }
            this.StoreMain.SuccessDepatch = true;
          }, 1500);
        }
      });
    },
    backProgress (value) {
      if (value === 1) {
        this.StoreResp.progress -= 4;
      }
      if (value === 2) {
        this.StoreResp.progress -= 3;
      }
      if (value === 3) {
        this.StoreResp.progress -= 2;
      }
      if (value === 4) {
        this.StoreResp.progress -= 1;
      }
    },
    AttachmentView () {
      for (let i = 0; i < this.StoreResp.documentData.attachments.length; i++) {
        this.attachments.push({ name: this.StoreResp.documentData.attachments[i].name });
      }
      this.loadignAttachment = true;
    },
    filteredItems (items) {
      return items.children.filter(item => item.send !== false);
    }
  }
};
</script>
<style>
.drop-zone--over {
  background-color: #d4edf7;
}

</style>
<template>
  <div class="container">
    <button type="button" ref="hiddenButton" class="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModalCenter"></button>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
          <button type="button" ref="hiddenButtonClose" class="close d-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <div class="modal-body">
            <span class="font-docs text-white d-flex justify-content-center">Concluindo envio, Por favor, aguarde...</span>
            <div class="progress" style="height: 30px;">
              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
              animation: progress-bar-stripes 1s linear infinite !important;
              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
              background-size: 0.5rem 1rem;
              transition: width .6s ease;
              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProgressBarDocs />
    <div class="container mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black ml-1"><b>Pré-visualização</b></h4>
        <font-awesome-icon :icon="['fas', 'pen']" class="Color-Docs" style="font-size:20px;cursor: pointer" @click="backProgress(1)"/>
      </div>
      <hr>
      <div v-if="!this.StoreMain.mobile" style="height: 100vh"><iframe :src="'data:application/pdf;base64,'+ this.StoreResp.pdf64" height="100%" width="100%"></iframe></div>
      <div v-if="this.StoreMain.mobile" style="height: 500px">
                <ViewrMobileResp />
            </div>
    </div>

    <div class="container mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black ml-1"><b>Anexos</b></h4>
        <font-awesome-icon :icon="['fas', 'pen']" class="Color-Docs" style="font-size:20px;cursor: pointer" @click="backProgress(2)"/>
      </div>
      <hr>
      <div v-if="loadignAttachment && !this.StoreMain.mobile" class='mt-2 mb-4'>
        <div class='mt-3'>
          <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
            style='height: 40px;align-items: center;'>
            <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
              {{attachment.name}}
            </span>
          </div>
      </div>

      <div class='mt-3' v-if="loadignAttachment && this.StoreMain.mobile">
          <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
            style='height: 40px;align-items: center;'>
            <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
              {{ attachment.name.substring(0, 15) }} {{ attachment.length > 15 ? '...' : '...' }}
            </span>
          </div>
    </div>

    <div class="container mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black ml-1"><b>Destinatários</b></h4>
        <font-awesome-icon :icon="['fas', 'pen']" class="Color-Docs" style="font-size:20px;cursor: pointer" @click="backProgress(3)"/>
      </div>
      <hr>
      <div v-if="this.StoreResp.SendToData.FirstToSendGroup !== undefined && this.StoreResp.SendToData.FirstToSend === 'share'" class="mt-3">
        <h4 class="font-docs text-docs-black ml-2"><b>Envio 1</b></h4>
          <div v-for="(ChoseDep, index) in this.StoreResp.SendToData.FirstToSendGroup" :key="ChoseDep">
            <DataTable :value='ChoseDep.children' :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{this.StoreResp.SendToData.FirstToSendGroup[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 20rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column header='Enviar cópia' class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <input type="checkbox" name="copy" :id="'inbox-' + slotProps.data.inbox" v-model="slotProps.data.inbox" style="width: 16px; height: 16px" @click="toggleInbox(slotProps.data)" disabled>
                </template>
              </Column>
            </DataTable>
      </div>
    </div>
      <div v-if="this.StoreResp.SendToData.FirstToSendSolo !== undefined && this.StoreResp.SendToData.FirstToSend === 'solo'" class="mt-3">
        <h4 class="font-docs text-docs-black ml-2"><b>Envio 1</b></h4>
          <div v-for="(ChoseDep, index) in this.StoreResp.SendToData.FirstToSendSolo" :key="ChoseDep">
            <DataTable :value="filteredItems(ChoseDep)" :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{this.StoreResp.SendToData.FirstToSendSolo[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
            </DataTable>
      </div>
      </div>

      <div v-if="this.StoreResp.SendToData.SecondToSendGroup !== undefined && this.StoreResp.SendToData.SecondToSendType === 'share'" class="mt-3">
        <h4 class="font-docs text-docs-black ml-2"><b>Envio 2</b></h4>
          <div v-for="(ChoseDep, index) in this.StoreResp.SendToData.SecondToSendGroup" :key="ChoseDep">
            <DataTable :value='ChoseDep.children' :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{this.StoreResp.SendToData.SecondToSendGroup[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 20rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column header='Enviar cópia' class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <input type="checkbox" name="copy" :id="'inbox-' + slotProps.data.inbox" v-model="slotProps.data.inbox" style="width: 16px; height: 16px" @click="toggleInbox(slotProps.data)" disabled>
                </template>
              </Column>
            </DataTable>
      </div>
    </div>
      <div v-if="this.StoreResp.SendToData.SecondToSend !== undefined && this.StoreResp.SendToData.SecondToSendType === 'solo'" class="mt-3">
        <h4 class="font-docs text-docs-black ml-2"><b>Envio 2</b></h4>
          <div v-for="(ChoseDep, index) in this.StoreResp.SendToData.SecondToSend" :key="ChoseDep">
            <DataTable :value="filteredItems(ChoseDep)" :responsive="true" :loading='loading'>
              <template #header>
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-docs text-bold" style="font-size: 16px">{{this.StoreResp.SendToData.SecondToSend[index].label}}</span>
                  </div>
              </template>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
            </DataTable>
      </div>
      </div>

      <div class="mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black ml-1"><b>Assinantes</b></h4>
        <font-awesome-icon :icon="['fas', 'pen']" class="Color-Docs" style="font-size:20px;cursor: pointer" @click="backProgress(4)"/>
      </div>
      <hr>
      <div v-if="this.StoreResp.SignersData.Signers.length >= 1" class="mt-3">
        <DataTable :value="this.StoreResp.SignersData.Signers" :responsive="true" :loading='loading'>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.StoreMain.mobile" class="d-flex">
                    <div>
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.StoreMain.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.StoreMain.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span  class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column field='departments' header='Setor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <span v-if="slotProps.data.departments.length <= 0">Departamento não definido</span>
                  <span v-if="slotProps.data.departments.length >= 1">{{slotProps.data.departments[0].title}}</span>
                </template>
              </Column>
              <Column header='Assinar anexo' class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" :id="'switch-' + slotProps.data.attachment_signer" v-model="slotProps.data.attachment_signer" disabled>
                  </div>
                </template>
              </Column>
               <Column header='Assinatura obrigatória' class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" :id="'switch-' + slotProps.data.signer" v-model="slotProps.data.signer" disabled>
                  </div>
                </template>
              </Column>
            </DataTable>
      </div>
  </div>
  <div class="d-flex justify-content-end align-items-center">
        <label class="label-docs text-docs-black mt-2 mr-2" for="flexSwitchCheckDefault"><b>Envio com urgência</b></label>
        <input type="checkbox" v-model="urgency">
    </div>
  <div v-if="this.StoreMain.mobile" class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress(4)">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Enviar</button>
    </div>
    </div>
    <div v-if="!this.StoreMain.mobile" class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress(4)">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Enviar</button>
    </div>
  </div>

</template>
