<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import ModalComponent from './ModalComponent.vue';
import ModalResponsable from './ModalResponsable.vue';
/* import Editor from '@tinymce/tinymce-vue'; */
import TodoListUpdated from './TodoListUpdate.vue';
import moment from 'moment';
export default {
  name: 'ModalTask',
  components: { ModalComponent, TodoListUpdated, ModalResponsable /* Editor */ },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      id: 0,
      viewSelect: 'Resume',
      TaskName: '',
      description: '',
      location: '',
      access_link: '',
      hour: '',
      situation: '',
      priority: '',
      responsible: [],
      attachments: [],
      CheckList: [],
      editCard: false,
      usersAll: [],
      users: [],
      ModelUser: [],
      Data: [],
      formatedDates: '',
      editDate: '',
      logsAll: [],
      situationChose: [
        { label: 'À iniciar', value: '1' },
        { label: 'Em andamento', value: '2' },
        { label: 'Concluída', value: '3' },
        { label: 'Bloqueado', value: '4' }
      ],
      priorityLabels: [
        { label: 'Baixa', value: 1 },
        { label: 'Média', value: 2 },
        { label: 'Alta', value: 3 }
      ],

      EditNameTask: '',
      EditDescriptionTask: '',
      Edithour: '',
      EditPlace: '',
      EditPlaceLink: '',
      EditPriority: '',
      EditSituation: '',
      EditAttachments: [],
      EditTack: [],
      ToRemove: [],
      Editusers: []
    };
  },

  watch: {
    ModelUser: function (value) {
      this.users = [];
      value.forEach(user => {
        this.usersAll.forEach(userAll => {
          if (user === userAll.id) {
            this.users.push(userAll);
          }
        });
      });

      AxiosAPI.put(`/api/tasks/${this.id}`, {
        users: this.users.map(user => user.id)
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.$emit('taskDescription');
      });
    }
  },

  methods: {
    openTask (value) {
      console.log(value);
      const dataUser = [];
      const ModelUser = [];
      this.id = value.id;
      this.TaskName = value.title;
      this.logsAll = value.logs.reverse();
      this.description = value.description;
      this.location = value.location;
      this.access_link = value.access_link;
      this.formatedDates = value.date;
      this.formatdateToEdit(value.date);
      this.hour = value.hour;
      this.situation = value.situation;
      this.priority = value.priority;
      this.responsible = value.responsible;
      this.viewSelect = 'Resume';
      this.attachments = value.attachments;
      this.CheckList = value.checklists;
      this.Store.EditresponsableTask = value.responsible;
      this.editCard = false;
      value.users.forEach(users => {
        dataUser.push(users);
        ModelUser.push(users.id);
      });

      const SlotUsers = [];
      this.Store.users.forEach(users => {
        users.value = users.id;
        users.label = users.name;
        SlotUsers.push(users);
      });

      this.usersAll = SlotUsers;
      this.users = dataUser;
      this.ModelUser = ModelUser;
      this.Data = value;
      value.checklists.isAddingItem = false;
      this.$refs.viewDocument.openModal();
    },

    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('dddd, DD [de] MMMM');
    },

    formatdateToEdit (value) {
      moment.locale('en-us');
      this.editDate = moment(value).format('yyyy-MM-DD');
    },

    calculateProgress (list) {
      if (list.items.length <= 0) {
        return 0;
      } else if (list.items.length === undefined) {
        return 0;
      } else {
        const totalItems = list.items.length;
        const completedItems = list.items.filter(item => item.is_completed).length;
        return totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;
      }
    },

    isChecked (labelId) {
      return this.Data.labels.some(label => label.id === labelId.id);
    },

    formatHour (value) {
      if (value === '00:00:00') {
        return 'Não informado';
      } else {
        return moment(value, 'HH:mm:ss').format('HH:mm');
      }
    },

    RemoveCard () {
      AxiosAPI.delete(`/api/tasks/${this.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.$emit('taskDescription');
        this.$refs.viewDocument.closeModal();
      });
    },

    addAttachment (event) {
      const files = Array.from(event.target.files);
      const newFiles = files.filter(file =>
        !this.EditTack.some(existingFile => existingFile.name === file.name)
      );

      this.EditAttachments.push(...newFiles.map(file => ({ name: file.name })));
      this.EditTack.push(...newFiles);
    },

    removeAttachment (index) {
      if (this.EditTack[index].id) {
        this.ToRemove.push(this.EditTack[index].id);
      }
      this.EditAttachments.splice(index, 1);
      this.EditTack.splice(index, 1);
      if (this.EditAttachments.length <= 0) {
        this.signerAttach = false;
      }
    },

    openFileInput () {
      this.$refs.fileInput.click();
    },

    editCardModel () {
      this.editCard = true;

      this.EditNameTask = this.TaskName;
      this.EditDescriptionTask = this.description;
      this.Edithour = this.formatHour(this.hour);
      this.EditPlace = this.location;
      this.EditPlaceLink = this.access_link;
      this.EditPriority = this.priority;
      this.EditSituation = this.situation;
      this.EditTack = this.attachments;
      this.Editusers = this.users;
    },

    editResponsable () {
      this.$refs.modalResponsable.openModalEdit();
    },

    EditTask () {
      const files = [];
      for (let i = 0; i < this.EditTack.length; i++) {
        files.push(this.EditTack[i]);
      }
      const users = [];
      for (let i = 0; i < this.Editusers.length; i++) {
        users.push(this.Editusers[i].id);
      }
      const OldAttachments = [];
      for (let i = 0; i < this.attachments.length; i++) {
        OldAttachments.push(this.attachments[i].id);
      }
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('title', this.EditNameTask);
      formData.append('description', this.EditDescriptionTask);
      formData.append('date', this.editDate);
      if (this.Edithour === 'Não informado') {
        formData.append('hour', '00:00');
      } else {
        formData.append('hour', this.Edithour);
      }

      formData.append('location', this.EditPlace);
      if (this.EditPlaceLink != null) {
        formData.append('access_link', this.EditPlaceLink);
      }
      if (this.EditPriority.value) {
        formData.append('priority', this.EditPriority.value);
      }
      if (this.EditSituation.value) {
        formData.append('situation', this.EditSituation.value);
      }

      formData.append('responsible_id', this.Store.EditresponsableTask.id);
      formData.append('users', users);

      if (this.attachments.length >= 1) {
        for (let i = 0; i < this.attachments.length; i++) {
          if (this.attachments[i].id) {
            formData.append('old_attachments[]', this.attachments[i].id);
          }
        }
      } else {
        formData.append('old_attachments[]', []);
      }

      for (let i = 0; i < this.EditTack.length; i++) {
        if (!this.EditTack[i].id) {
          formData.append('new_attachments[]', this.EditTack[i]);
        }
      }

      AxiosAPI.post(`/api/tasks/${this.id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.EditNameTask = '';
        this.EditDescriptionTask = '';
        this.Users = [];
        this.DateStart = '';
        this.Edithour = '';
        this.EditPlace = '';
        this.EditPlaceLink = '';
        this.EditPriority = '';
        this.status = { label: 'À iniciar', value: 1, severity: 'secondary' };
        this.attachments = [];
        this.EditTack = [];

        this.emitter.emit('KabanUpdated');
        this.$refs.viewDocument.closeModal();
      });
    },

    printPdf (value, despacho) {
      console.log(this.Store.host + despacho.file_path);
      const printPdf = window.open(this.Store.host + despacho.file_path, '', 'width=800,height=600');
      printPdf.onload = () => {
        printPdf.print();
      };
    }

  }
};
</script>
<style scoped>
.dropdown-content p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}
.dropdown-content p:hover {
  background-color: #f0f0f0;
}
</style>
<template>
    <div>
      <ModalResponsable ref="modalResponsable"/>
        <ModalComponent ref="viewDocument">
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                  <div>
                      <span class="font-docs text-black" style="font-size: 20px"><strong>Atividade</strong></span>
                  </div>

                  <div class="d-flex align-items-center">
                    <i v-if="!editCard && this.Store.EditresponsableTask.id === this.Store.user.id" class="fa-solid fa-pen Color-Docs mr-3" style="font-size: 20px; cursor:pointer" @click="editCardModel"></i>
                    <i v-if="!editCard && this.Store.EditresponsableTask.id === this.Store.user.id" class="fa-solid fa-trash Color-Docs mr-3" style="font-size: 20px; cursor:pointer" @click="RemoveCard"></i>
                      <button
                      id="close"
                      type="button"
                      class="close"
                      @click="$refs.viewDocument.closeModal();"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                </div>
            </template>
            <template #body>
            <div class="d-flex">

              <div class="w-20 mt-3">
                <div class="d-flex align-items-center" style="cursor:pointer" @click="viewSelect = 'Resume'">
                  <i class="fa-solid fa-newspaper mr-2"></i>
                  <span class="font-docs">Resumo</span>
                </div>

                <div class="d-flex align-items-center mt-3" style="cursor:pointer" @click="viewSelect = 'Files'">
                  <i class="fa-brands fa-gg mr-2"></i>
                  <span class="font-docs">Arquivos</span>
                </div>

                <div class="d-flex align-items-center mt-3" style="cursor:pointer" @click="viewSelect = 'Convidados'">
                  <i class="fa-solid fa-users mr-2"></i>
                  <span class="font-docs">Convidados</span>
                </div>

                <div class="d-flex align-items-center mt-3" style="cursor:pointer" @click="viewSelect = 'timeline'">
                  <i class="fa-regular fa-clock mr-2"></i>
                  <span class="font-docs">Histórico</span>
                </div>
              </div>

              <div class="w-75" v-if="viewSelect === 'Resume'">

                <div v-if="!editCard" class="mt-3">
                  <span class="font-docs"> <b>{{ TaskName }}</b></span>
                </div>

                <div v-if="editCard" class="mt-3">
                  <label class="label-docs">Nome <span style="color:red">*</span></label>
                  <input type="text" class="my_form" v-model="EditNameTask" placeholder="Nome da tarefa" />
                </div>

                <div v-if="!editCard" class="d-flex justify-content-between align-items-center mt-3">
                  <div class="d-flex">
                    <i class="fa-regular fa-calendar-check mr-2" style="font-size: 20px;color:#6C757D"></i>
                    <div class="d-grid">
                      <span class="font-docs"><b>Data e hora</b></span>
                      <span class="font-docs" style="color:#6C757D"> {{ formatDate(formatedDates) }} - {{ formatHour(hour) }}</span>
                    </div>
                  </div>

                    <div class="d-grid">
                      <span class="font-docs"><b>Situação</b></span>
                      <Tag v-if="situation === '1'" rounded style='background-color: #E9ECEF;color:#6C757D;'>
                        <div class='flex align-items-center gap-2'>
                          <span class='text-base font-docs'><b>À iniciar</b></span>
                        </div>
                      </Tag>
                      <Tag v-if="situation === '2'" rounded style='background-color: #CFF4FC;color: #087990;'>
                        <div class='flex align-items-center gap-2'>
                          <span class='text-base font-docs'><b>Em andamento</b></span>
                        </div>
                      </Tag>
                      <Tag v-if="situation === '4'" rounded style='background-color: #F8D7DA;color: #842029;'>
                        <div class='flex align-items-center gap-2'>
                          <span class='text-base font-docs'><b>Bloqueado</b></span>
                        </div>
                      </Tag>
                      <Tag v-if="situation === '3'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                        <div class='flex align-items-center gap-2'>
                          <span class='text-base font-docs'><b>Concluída</b></span>
                        </div>
                      </Tag>
                    </div>

                    <div class="d-grid">
                      <span class="font-docs"><b>Prioridade</b></span>
                        <div v-if="priority === '1'" class="d-flex">
                          <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                          <span>
                            Baixa
                          </span>
                          </div>

                          <div v-if="priority === '2'" class="d-flex">
                            <div class="d-grid mr-1">
                              <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                              <i class="fa-solid fa-angle-up" style="color:#FFC107;position: relative;bottom: 10px;"></i>
                            </div>
                            <span>
                              Média
                            </span>
                          </div>

                          <div v-if="priority === '3'" class="d-flex">
                            <div class="d-grid mr-1">
                              <i class="fa-solid fa-angle-up" style="color:#F76659;"></i>
                              <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative;bottom: 10px;"></i>
                              <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative;bottom: 20px;"></i>
                            </div>
                            <span>
                              Alta
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="editCard">
                  <div class="row">
                            <div class="col">
                                <label class="label-docs mt-3">Data <span style="color:red">*</span></label>
                                <input type="date" class="my_form" v-model="editDate" />
                            </div>
                            <div class="col">
                                <label class="label-docs mt-3">Horario</label>
                                <input type="time" class="my_form" v-model="Edithour" />
                            </div>
                            <div class="col">
                                <label class="label-docs mt-3">Situação <span style="color:red">*</span></label>
                              <Dropdown
                                v-model="EditSituation"
                                :options="situationChose"
                                optionLabel="label"
                                placeholder="Selecione um status"
                                class="my_form"
                                style="padding: 0rem 0.75rem !important"
                              >
                              <template #value="slotProps">
                                  <div class="flex align-items-center">
                                    <Tag v-if="slotProps.value === '1'" rounded style='background-color: #E9ECEF;color:#6C757D;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>À iniciar</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value === '2'" rounded style='background-color: #CFF4FC;color: #087990;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>Em andamento</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value === '4'" rounded style='background-color: #F8D7DA;color: #842029;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>Bloqueado</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value === '3'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>Concluída</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.value === '1'" rounded style='background-color: #E9ECEF;color:#6C757D;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>À iniciar</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.value === '2'" rounded style='background-color: #CFF4FC;color: #087990;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>Em andamento</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.value === '4'" rounded style='background-color: #F8D7DA;color: #842029;padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>Bloqueado</b></span>
                                      </div>
                                    </Tag>
                                    <Tag v-if="slotProps.value.value === '3'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);padding: 0rem 0.75rem !important;'>
                                      <div class='flex align-items-center gap-2'>
                                        <span class='text-base font-docs'><b>Concluída</b></span>
                                      </div>
                                    </Tag>
                                  </div>
                              </template>
                                <template #option="{ option }">
                                <Tag v-if="option.label === 'À iniciar'" rounded style='background-color: #E9ECEF;color:#6C757D;'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                                <Tag v-if="option.label === 'Em andamento'" rounded style='background-color: #CFF4FC;color: #087990;'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                                <Tag v-if="option.label === 'Bloqueado'" rounded style='background-color: #F8D7DA;color: #842029;'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                                <Tag v-if="option.label === 'Concluída'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                                  <div class='flex align-items-center gap-2'>
                                    <span class='text-base font-docs'><b>{{ option.label }}</b></span>
                                  </div>
                                </Tag>
                              </template>
                            </Dropdown>
                            </div>
                            <div class="col">
                                <label class="label-docs mt-3">Prioridade</label>

                                <Dropdown
                                v-model="EditPriority"
                                :options="priorityLabels"
                                optionLabel="label"
                                placeholder="Selecione um status"
                                class="my_form"
                                style="padding: 0rem 0.75rem !important"
                              >
                              <template #value="slotProps">
                                  <div class="flex align-items-center">
                                    <div v-if="slotProps.value.value === 1" class="d-flex">
                                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                                    <span>
                                      Baixa
                                    </span>
                                    </div>

                                    <div v-if="slotProps.value.value === 2" class="d-flex">
                                      <div class="d-grid mr-1">
                                        <i class="fa-solid fa-angle-up" style="color:#FFC107;position: absolute; top: 20px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                                      </div>
                                      <span>
                                        Média
                                      </span>
                                    </div>

                                    <div v-if="slotProps.value.value === 3" class="d-flex">
                                      <div class="d-grid mr-1">
                                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 18px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 24px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#F76659;"></i>
                                      </div>
                                      <span>
                                        Alta
                                      </span>
                                    </div>

                                    <div v-if="slotProps.value === '1'" class="d-flex">
                                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                                    <span>
                                      Baixa
                                    </span>
                                    </div>

                                    <div v-if="slotProps.value === '2'" class="d-flex">
                                      <div class="d-grid mr-1">
                                        <i class="fa-solid fa-angle-up" style="color:#FFC107;position: absolute; top: 20px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                                      </div>
                                      <span>
                                        Média
                                      </span>
                                    </div>

                                    <div v-if="slotProps.value === '3'" class="d-flex">
                                      <div class="d-grid mr-1">
                                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 18px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 24px;"></i>
                                        <i class="fa-solid fa-angle-up" style="color:#F76659;"></i>
                                      </div>
                                      <span>
                                        Alta
                                      </span>
                                    </div>
                                  </div>
                              </template>
                                <template #option="{ option }">
                                  <div v-if="option.label === 'Baixa'" class="d-flex">
                                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                                    <span>
                                      {{ option.label }}
                                    </span>
                                  </div>

                                  <div v-if="option.label === 'Média'" class="d-flex">
                                    <div class="d-grid mr-1">
                                      <i class="fa-solid fa-angle-up" style="color:#FFC107;position: absolute; top: 20px;"></i>
                                      <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                                    </div>
                                    <span>
                                      {{ option.label }}
                                    </span>
                                  </div>

                                  <div v-if="option.label === 'Alta'" class="d-flex">
                                    <div class="d-grid mr-1">
                                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 18px;"></i>
                                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: absolute; top: 24px;"></i>
                                      <i class="fa-solid fa-angle-up" style="color:#F76659;"></i>
                                    </div>
                                    <span>
                                      {{ option.label }}
                                    </span>
                                  </div>
                              </template>
                            </Dropdown>
                            </div>
                        </div>
                </div>

                <div v-if="!editCard" class="d-flex mt-3">
                  <div class="d-flex">
                    <i class="fa-solid fa-bars-staggered mr-2" aria-hidden="true" style="font-size:20px;color:#6C757D"></i>
                    <div class="d-grid">
                      <span class="font-docs"><b>Descrição</b></span>
                      <span class="font-docs" style="color:#6C757D">{{ description }}</span>
                    </div>
                  </div>
                </div>

                <div v-if="editCard" class="mt-3">
                  <label class="label-docs mt-1">Descrição</label>
                  <textarea name="Description" id="Description" v-model="EditDescriptionTask" class="my_form" style="height: 86px;"></textarea>
                </div>

                <div v-if="!editCard" class="d-flex mt-3">
                  <div class="d-flex">
                    <i class="fa-solid fa-location-dot mr-2" style="font-size:20px;color:#6C757D"></i>
                    <div class="d-grid">
                      <span class="font-docs"><b>Local</b></span>
                      <span v-if="location" class="font-docs" style="color:#6C757D">{{ location }}</span>
                      <span v-if="!location" class="font-docs" style="color:#6C757D">Não informado</span>
                      <a class="font-docs" :href="access_link" style="color:#6C757D">{{ access_link }}</a>
                    </div>
                  </div>
                </div>

                <div v-if="editCard" class="row">
                    <div class="col">
                        <label class="label-docs mt-3">local</label>
                        <input type="text" v-model="EditPlace" class="my_form" placeholder="Meet" />
                      </div>

                      <div class="col">
                        <label class="label-docs mt-3">Link de acesso à video conferência</label>
                        <input type="text" v-model="EditPlaceLink" class="my_form" placeholder="https://meet.google.com/"/>
                      </div>
                  </div>

                <div class="d-grid mt-3">
                  <span class="font-docs mt-3">Responsável</span>
                    <div v-if="!editCard" class="d-flex align-items-center mt-1">
                      <img
                      :src="this.Store.host + responsible.path_image"
                      class="mr-2"
                      style="height: 40px; border-radius: 50%;">
                      <div class="d-grid">
                        <span class="font-docs">
                          <b>{{ responsible.name }}</b>
                        </span>
                        <span class="font-docs" style="font-size: 14px">
                          {{ responsible.email }}
                        </span>
                      </div>
                    </div>

                    <div v-if="editCard" class="d-flex align-items-center mt-1">
                      <img
                      :src="this.Store.host + this.Store.EditresponsableTask.path_image"
                      class="mr-2"
                      style="height: 40px; border-radius: 50%;">
                      <div class="d-grid">
                        <span class="font-docs">
                          <b>{{ this.Store.EditresponsableTask.name }}</b>
                        </span>
                        <span class="font-docs" style="font-size: 14px">
                          {{ this.Store.EditresponsableTask.email }}
                        </span>
                      </div>
                      <i v-if="editCard" class="fa-solid fa-pen Color-Docs ml-2" style="font-size: 25px; cursor:pointer" @click="editResponsable"></i>
                    </div>
                  </div>

                 <div v-if="CheckList">
                  <TodoListUpdated ref="TodoListUpdated" :ExistCheckList="CheckList" :TaskID="id"/>
                 </div>

                </div>

                <div class="w-75" v-if="viewSelect === 'Files'">
                  <div v-if="attachments.length >= 1 && !editCard" class='mt-3 mb-4'>
                    <div class='mt-3'>
                      <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
                        style='height: 40px;align-items: center;'>
                        <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
                          {{attachment.name}}
                          <div class="d-flex ml-2" style="cursor:pointer" @click="printPdf(3, attachment)">
                              <font-awesome-icon :icon="['fas', 'download']" />
                          </div>
                        </span>
                      </div>
                  </div>
                  <div v-if="attachments.length <= 0 && !editCard" class='mt-3'>
                    <span class="font-docs" style="color:#6C757D">Nenhum anexo nessa atividade</span>
                  </div>

                <div v-if="editCard">
                  <button class="btn Color-Docs mt-5" style="border: 1px solid #F1860B" @click='openFileInput'>
                      <i class="fa-solid fa-arrow-up-from-bracket mr-2"></i>
                      <input type='file' ref='fileInput' @change='addAttachment' accept='.doc,.docx,.odt,.pdf,.png,.jpg,.svg' style='display: none' multiple/>
                      Adicionar arquivo
                  </button>

                  <div class='mt-2 mb-4'>
                    <div class='mt-3'>
                      <span v-for='(attachment, index) in attachments' :key='index' class='d-flex ml-2 Color-Docs'
                        style='height: 40px;align-items: center;'>
                        <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
                          {{attachment.name}}
                            <button type='button' @click='removeAttachment(index)'
                              style='border: none;'>
                              <i class="fa fa-trash-o ml-2" aria-hidden="true" style="color: #212529"></i>
                            </button>
                        </span>
                      </div>
                  </div>
                </div>
              </div>

                <div class="w-75" v-if="viewSelect === 'Convidados'">
                  <div class="mt-3">
                      <span class="font-docs"><b>Convidados</b></span>
                    </div>

                    <div v-if="!editCard">
                    <div class="mt-2" v-for="user in users" :key="user">
                      <div class="d-flex align-items-center">
                        <img
                        :src="this.Store.host + user.path_image"
                        class="mr-2"
                        style="height: 40px; border-radius: 50%;">
                        <div class="d-grid">
                          <span class="font-docs">
                            <b>{{ user.name }}</b>
                          </span>
                          <span class="font-docs" style="font-size: 14px">
                            {{ user.email }}
                          </span>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div v-if="editCard" >
                      <MultiSelectPrime
                          display="chip"
                          v-model="Editusers"
                          :options="Store.users"
                          filter optionLabel="name"
                          placeholder="Selecione os envolvidos na tarefa"
                          :maxSelectedLabels="3"
                          style="z-index: 9999 !important; height: calc(3.475rem + 2px);"
                          class="w-full my_form">

                          <template #option="slotProps">
                              <div class="d-flex align-items-center">
                                  <img :alt="slotProps.option.name" :src="this.Store.host + slotProps.option.path_image" class=" mr-2" style="height: 30px; border-radius: 50%;" />
                                  <div>{{ slotProps.option.name }}</div>
                              </div>
                          </template>
                          <template #footer>
                              <div class="py-2 px-3">
                                  <b>{{ Users ? Users.length : 0 }}</b> usuário(s){{ (Users ? Users.length : 0) > 1 ? 's' : '' }} selecionados.
                              </div>
                          </template>
                          </MultiSelectPrime>
                    </div>
                </div>

                <div class="w-75" v-if="viewSelect === 'timeline'">
                  <div class="mt-3">
                      <span class="font-docs"><b>Histórico de atividade</b></span>
                  </div>

                  <div>
                    <Timeline :value="logsAll">
                        <template #opposite="slotProps">
                          <div class="d-grid">
                            <span class="font-docs" style="color:#465668">{{ formatDate(slotProps.item.created_at) }}</span>
                            <span class="font-docs" style="color:#465668">xx:xx</span>
                            <!-- <span class="font-docs" style="color:#465668">{{ formatHour(slotProps.item.created_at) }}</span> -->
                          </div>
                        </template>
                        <template #content="slotProps">
                          <span class="font-docs-recived-header" style="color:#465668">{{slotProps.item.user.name}}</span>
                          <div>
                            <span v-if="slotProps.item.type === '0'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '1'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '2'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '3'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '4'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '5'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '6'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '7'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '8'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                            <span v-if="slotProps.item.type === '9'" class="font-docs" style="color:#465668">{{ slotProps.item.message }}</span>
                          </div>
                        </template>
                    </Timeline>
                  </div>

                </div>
                </div>
            </template>
            <template #footer>
              <div v-if="editCard" class="d-flex justify-content-end mt-4">
                  <button class="btn Color-Docs font-docs mr-3" style="border: 1px solid #F1860B" @click="editCard = false">
                      Cancelar
                  </button>
                  <button class="btn background-Docs text-white font-docs" @click="EditTask()">
                      Salvar
                  </button>
                </div>
            </template>
        </ModalComponent>
    </div>
</template>
<style>
</style>
