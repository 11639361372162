<script>
import routerpath from '../../router/routerpath';
import SidenavItem from './SidenavItem.vue';
import SidenavCollapse from './SidenavCollapse.vue';
import { useMainStore } from '../../stores/index';
import Cookie from 'js-cookie';
export default {
  name: 'SidenavList',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SidenavItem,
    // eslint-disable-next-line vue/no-unused-components
    SidenavCollapse
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      activeTab: '',
      WidthLess: false,
      Loading: true,
      external: false,
      systemNow: ''
    };
  },
  watch: {
    'Store.Menu': function (newValue, oldPath) {
      if (newValue === true) {
        this.WidthLess = true;
      }
      if (newValue === false) {
        this.WidthLess = false;
      }
    },
    '$route.path': function (newValue, oldPath) {
      const routeArr = this.$route.path.split(routerpath + '/');
      this.activeTab = routeArr[1];
    }
  },
  mounted () {
    this.systemNow = '/' + routerpath;
    /* console.log(this.$route.path != this.systemNow + '/');
    console.log(this.$route.path != this.systemNow + '/' && this.$route.path != systemNow + '/' + routerpath + '/Home'); */
    const OnSystem = setInterval(() => {
      if (this.$route.name === null || this.$route.name === undefined || this.$route.name !== 'Login') {
        if (this.$route.path !== '/' + routerpath + '/') {
          clearInterval(OnSystem);
          const routeArr = this.$route.path.split(routerpath + '/');
          this.activeTab = routeArr[1];
        }
      }
    }, 500);
  },

  created () {
    const OnSystem = setInterval(() => {
      if (this.Store.user.id !== undefined) {
        clearInterval(OnSystem);
        this.Store.user.abilities.forEach(abilitie => {
          if (abilitie.id === 5) {
            this.external = true;
          }
        });
        this.Loading = false;
      }
    }, 500);
  },
  methods: {
    toggle (value) {
      this.Store.toggleConfigurator();

      if (value === 1) {
        this.Store.isPinned = false;
      }
      if (value === 2) {
        this.Store.isPinned = true;
      }
    },
    toggleLess () {
      this.Store.Menu = false;
    },
    setActiveTab (tabName) {
      this.activeTab = tabName;
    },

    getRoute () {
      const routeArr = this.$route.path.split(routerpath + '/');
      return routeArr[1];
    },

    logout () {
      Cookie.remove('User');
      Cookie.remove('Session_ID');
      location.reload();
    },

    resetCreate () {
      this.Store.progress = 1;
      this.Store.draftID = 0;
      this.Store.is_new_document = true;
      this.Store.SendToData = [];
      this.Store.FirstIndividuallySend = [];
      this.Store.SecondIndividuallySend = [];
      this.Store.tableNoFormat = [];
      this.Store.SendTwo = false;
      this.Store.SecondSend = false;
      this.Store.tableKeys = [];
      this.Store.documentData = [];
      this.Store.SignersData = [];
      this.Store.documentData = { typeDoc: 0, attachments: [], subject: '', body: '', status_sign: 0, user_id: 0, to_users: [], to_departments: [] };
    }
  }
};
</script>
<style>
</style>
<template>
  <div>
    <div v-if="this.Store.isPinned && !this.Store.mobile" id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100 overflow-hidden">
    <div v-if="Loading"></div>
      <div v-if="!Loading">
      <ul v-if="!external" class="navbar-nav">
        <ul class="nav">
          <li
          :class="{ 'active': activeTab === 'Home' && this.$route.path != systemNow + '/' }"
              class="nav-item box-link mb-2 nav-item-hover-person mt-3">
            <router-link :to="{ name: 'Home' }" exact>
              <a @click="setActiveTab('Home')" class="nav-link">
                <font-awesome-icon class="mr-3"
                  :class="{ 'text-black': activeTab === 'Home' && this.$route.path != systemNow + '/', 'text-white': activeTab !== 'Home' }"
                  :icon="['fas', 'house-chimney']"  />
                <span class="sidenav-normal font-docs fonts-docs-size"
                  :class="{ 'text-black': activeTab === 'Home' && this.$route.path != systemNow + '/', 'text-white': activeTab !== 'Home' }"
                  >Início</span>
              </a>
            </router-link>
          </li>
          </ul>

        <div class="d-flex justify-content-center mt-2">
              <img src="../../assets/img/line_down.png" style="width: 90%;">
          </div>
        <li class="nav-item">

          <ul class="nav">
            <ul class="nav mt-2 ms-3">
            <div class="d-flex">
              <li class="nav-item navbar-brand p0-extend header-size">
                <span class="sidenav-normal text-white font-docs header-size">Documentos</span>
              </li>
            </div>
          </ul>
            <li
            class="nav-item active box-link mb-2"  style="background-color: #F1860B !important" @click="resetCreate()">
              <router-link :to="{ name: 'Criar de documento' }" exact>
                <a @click="setActiveTab('Criar de documento')" class="active nav-link" style="background-color: #F1860B !important">
                  <font-awesome-icon class="mr-2"
                    :class="{ 'text-white': activeTab === 'Criar de documento' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'Criar de documento' }"
                    :icon="['fas', 'plus']" />
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-white': activeTab === 'Criar de documento' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'Criar de documento' }"
                    >Criar documento</span>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item nav-item-side box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Recebidos' }" exact>
                <a @click="setActiveTab('Recebidos')" class="nav-link">
                  <div v-if="this.Store.NotViewedTrue === 0">
                    <font-awesome-icon class="mr-3"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                      :icon="['fas', 'inbox']" />
                    <span class="sidenav-normal font-docs fonts-docs-size"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                      >Recebidos
                      </span>
                    </div>
                    <div v-if="this.Store.NotViewedTrue >= 1" class="d-flex justify-content-between w-100 align-items-center">
                      <div>
                        <font-awesome-icon class="mr-3"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                      :icon="['fas', 'inbox']" />
                    <span class="sidenav-normal font-docs fonts-docs-size"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                      >Recebidos</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-center" style="width: 20px; height: 20px; background-color: #0DCAF0; border-radius: 50%">
                        <span class="font-docs text-black"> {{ this.Store.NotViewedTrue }}</span>
                      </div>
                  </div>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Enviados' }" exact>
                <a @click="setActiveTab('Enviados')" class="nav-link">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Enviados' }"
                    :icon="['fas', 'paper-plane']"/>
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Enviados' }"
                    >Enviados</span>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item nav-item-side box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Assinaturas' }" exact>
                <a @click="setActiveTab('Assinaturas')" class="nav-link">
                  <div v-if="this.Store.DocLessSignTrue === 0">
                    <font-awesome-icon class="mr-3"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                      :icon="['fas', 'pen-clip']"/>
                    <span class="sidenav-normal font-docs fonts-docs-size"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                      >Assinaturas</span>
                    </div>
                    <div v-if="this.Store.DocLessSignTrue >= 1" class="d-flex justify-content-between w-100 align-items-center">
                      <div>
                        <font-awesome-icon class="mr-3"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                      :icon="['fas', 'inbox']" />
                    <span class="sidenav-normal font-docs fonts-docs-size"
                      :class="{ 'text-black': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                      >Assinaturas</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-center" style="width: 20px; height: 20px; background-color: #0DCAF0; border-radius: 50%">
                        <span class="font-docs text-black"> {{ this.Store.DocLessSignTrue }}</span>
                      </div>
                  </div>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Favoritos' }" exact>
                <a @click="setActiveTab('Favoritos')" class="nav-link">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Favoritos' }"
                    :icon="['fas', 'star']"  />
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Favoritos' }"
                    >Favoritos</span>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Todos os documentos' }" exact>
                <a @click="setActiveTab('Todos')" class="nav-link">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Todos' }"
                    :icon="['fas', 'file']"  />
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Todos' }"
                    >Todos os documentos</span>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Models' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Rascunhos' }" exact>
                <a @click="setActiveTab('Rascunhos')" class="nav-link">
                  <i :class="{ 'text-black': activeTab.split('/')[0] === 'Models' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Models' }" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Models' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Models' }"
                    >Rascunhos</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>

        <div class="d-flex justify-content-center mt-2">
          <img src="../../assets/img/line_down.png" style="width: 90%;">
        </div>

        <ul class="nav ms-3">
          <div class="d-flex mt-2">
            <li class="nav-item navbar-brand p0-extend">
              <span class="sidenav-normal font-docs fonts-docs-size text-white header-size">Gerenciamento</span>
            </li>
          </div>
        </ul>

        <li class="nav-item">

          <ul class="nav">
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Setores' }" exact>
                <a @click="setActiveTab('Setores')" class="nav-link">
                  <font-awesome-icon class="mr-3" :class="{ 'text-black': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Setores' }"
                  :icon="['fas', 'id-badge']" style="font-size: 19px;"/>
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Setores' }"
                    >Setores</span>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link nav-item-hover-person">
              <router-link :to="{ name: 'Servidores' }" exact>
                <a @click="setActiveTab('Servidores')" class="nav-link">
                    <font-awesome-icon class="mr-3"  :class="{ 'text-black': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Servidores' }"
                    :icon="['fas', 'user-group']" flip="horizontal" />
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Servidores' }"
                    >Servidores</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>
        <div class="d-flex justify-content-center">
          <img src="../../assets/img/line_down.png" style="width: 90%;">
        </div>
        <li class="nav-item">

          <ul class="nav">
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link nav-item-hover-person">
              <router-link :to="{ name: 'Suporte' }" exact>
                <a @click="setActiveTab('Suporte')" class="nav-link">
                  <font-awesome-icon class="mr-3"  :class="{ 'text-black': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Suporte' }"
                    :icon="['fas', 'phone']" />
                  <span class="sidenav-normal font-docs fonts-docs-size"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Suporte' }"
                    >Suporte</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    </div>

    <div v-if="!this.Store.isPinned && !this.Store.mobile" class="collapse navbar-collapse w-auto h-auto h-100 overflow-hidden">
    <div v-if="Loading"></div>
      <div v-if="!Loading">
      <ul v-if="!external" class="navbar-nav align-items-center">
        <li class="nav-item-pined">

        <ul class="nav justify-content-center align-items-center">
          <li :class="{ 'active': activeTab === 'Home' && this.$route.path != systemNow + '/' }"
          class="nav-item-pined box-link-pined mt-3 nav-item-hover-person-pined">
            <router-link :to="{ name: 'Home' }" exact>
              <a @click="setActiveTab('Home')" class="nav-link text-white nav-marg-pined">
                <font-awesome-icon class="text-white"
                  :class="{ 'text-white': activeTab === 'Home' && this.$route.path != systemNow + '/', 'text-white': activeTab !== 'Home' }"
                  :icon="['fas', 'house-chimney']"  />
              </a>
            </router-link>
          </li>
          </ul>
        </li>

        <div class="d-flex justify-content-center mt-2 mb-2">
              <img src="../../assets/img/line_down.png" style="width: 90%;">
          </div>
        <li class="nav-item-pined">

          <ul class="nav justify-content-center align-items-center">
            <li
            class="nav-item-pined active box-link-pined mb-2"  style="background-color: #F1860B !important"  @click="resetCreate()">
              <router-link :to="{ name: 'Criar de documento' }" exact>
                <a @click="setActiveTab('Criar de documento')" class="active nav-link text-white nav-marg-pined" style="background-color: #F1860B !important">
                  <font-awesome-icon
                    :class="{ 'text-white': activeTab === 'Criar de documento' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'Criar de documento' }"
                    :icon="['fas', 'plus']" />
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class=" nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Recebidos' }" exact>
                <a @click="setActiveTab('Recebidos')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon
                    :class="{ 'text-white': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                    :icon="['fas', 'inbox']" />
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Enviados' }" exact>
                <a @click="setActiveTab('Enviados')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon
                    :class="{ 'text-white': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Enviados' }"
                    :icon="['fas', 'paper-plane']"/>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Assinaturas' }" exact>
                <a @click="setActiveTab('Assinaturas')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon
                    :class="{ 'text-white': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                    :icon="['fas', 'pen-clip']"/>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Favoritos' }" exact>
                <a @click="setActiveTab('Favoritos')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon
                    :class="{ 'text-white': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Favoritos' }"
                    :icon="['fas', 'star']"  style="font-size: 12px;"/>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Todos os documentos' }" exact>
                <a @click="setActiveTab('Todos')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon
                    :class="{ 'text-white': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Todos' }"
                    :icon="['fas', 'file']"  style="font-size: 12px;"/>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Models' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Rascunhos' }" exact>
                <a @click="setActiveTab('Rascunhos')" class="nav-link text-white nav-marg-pined">
                  <i :class="{ 'text-white': activeTab === '' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== '' }" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </a>
              </router-link>
            </li>

          </ul>
        </li>

        <div class="d-flex justify-content-center mt-2">
          <img src="../../assets/img/line_down.png" style="width: 90%;">
        </div>

        <li class="nav-item-pined mt-2 mb-2">

          <ul class="nav justify-content-center align-items-center">
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined mb-2 nav-item-hover-person-pined">
              <router-link :to="{ name: 'Setores' }" exact>
                <a @click="setActiveTab('Setores')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon :class="{ 'text-white': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Setores' }"
                  :icon="['fas', 'id-badge']" style="font-size: 19px;" />
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined nav-item-hover-person-pined">
              <router-link :to="{ name: 'Servidores' }" exact>
                <a @click="setActiveTab('Servidores')" class="nav-link text-white nav-marg-pined">
                    <font-awesome-icon  :class="{ 'text-white': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Servidores' }"
                    :icon="['fas', 'user-group']" flip="horizontal" />
                </a>
              </router-link>
            </li>
          </ul>
        </li>
        <div class="d-flex justify-content-center">
          <img src="../../assets/img/line_down.png" style="width: 90%;">
        </div>
        <li class="nav-item-pined mt-2">

          <ul class="nav justify-content-center align-items-center">
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-pined box-link-pined nav-item-hover-person-pined">
              <router-link :to="{ name: 'Suporte' }" exact>
                <a @click="setActiveTab('Suporte')" class="nav-link text-white nav-marg-pined">
                  <font-awesome-icon class="text-white" icon="fa-solid fa-phone" />
                </a>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    </div>

    <div v-if="this.Store.isPinned && this.Store.mobile" id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100 overflow-hidden">
    <div v-if="Loading"></div>
      <div v-if="!Loading">
      <ul v-if="!external" class="navbar-nav">
        <li class="nav-item">

        <ul class="nav">
          <li
              :class="{ 'active': activeTab === 'Home' && this.$route.path != systemNow + '/' }"
              class="nav-item-mobile box-link mt-3 mobile-btn-pained">
              <router-link :to="{ name: 'Home' }" exact>
                <a @click="setActiveTab('Home'); this.Store.isPinned = false" class="nav-link text-black" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab === 'Home' && this.$route.path != systemNow + '/', 'text-white': activeTab !== 'Home' }"
                    :icon="['fas', 'house-chimney']" />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab === 'Home' && this.$route.path != systemNow + '/', 'text-white': activeTab !== 'Home' }"
                    >Início</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>

        <div class="d-flex justify-content-center mt-2">
              <img src="../../assets/img/line_down.png" style="width: 95%; height: 2px;">
          </div>
        <li class="nav-item">

          <ul class="nav">
            <ul class="nav mt-2 ms-3">
            <div class="d-flex">
              <li class="nav-item navbar-brand p0-extend header-size">
                <span class="sidenav-normal text-white font-docs header-size" style="font-size: 14px">Documentos</span>
              </li>
            </div>
          </ul>
            <li
            class="nav-item-mobile active box-link mb-2 mobile-btn-pained d-flex justify-content-center pr-2"  style="background-color: #F1860B !important"  @click="resetCreate()">
              <router-link :to="{ name: 'Criar de documento' }" exact>
                <a @click="setActiveTab('Criar de documento'); this.Store.isPinned = false" class="active nav-link text-white" style="background-color: #F1860B !important; padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-2"
                    :class="{ 'text-white': activeTab === 'Criar de documento' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'Criar de documento' }"
                    :icon="['fas', 'plus']" />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-white': activeTab === 'Criar de documento' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'Criar de documento' }"
                    >Criar documento</span>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mb-2 mobile-btn-pained">
              <router-link :to="{ name: 'Recebidos' }" exact>
                <a @click="setActiveTab('Recebidos'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                    :icon="['fas', 'inbox']" />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Recebidos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Recebidos' }"
                    >Recebidos</span>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mb-2 mobile-btn-pained">
              <router-link :to="{ name: 'Enviados' }" exact>
                <a @click="setActiveTab('Enviados'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Enviados' }"
                    :icon="['fas', 'paper-plane']"/>
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Enviados' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Enviados' }"
                    >Enviados</span>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mb-2 mobile-btn-pained">
              <router-link :to="{ name: 'Assinaturas' }" exact>
                <a @click="setActiveTab('Assinaturas'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                    :icon="['fas', 'pen-clip']"/>
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Assinaturas' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Assinaturas' }"
                    >Assinaturas</span>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mb-2 mobile-btn-pained">
              <router-link :to="{ name: 'Favoritos' }" exact>
                <a @click="setActiveTab('Favoritos'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Favoritos' }"
                    :icon="['fas', 'star']"  />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Favoritos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Favoritos' }"
                    >Favoritos</span>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item box-link mb-2 nav-item-hover-person">
              <router-link :to="{ name: 'Todos os documentos' }" exact>
                <a @click="setActiveTab('Todos')" class="nav-link">
                  <font-awesome-icon class="mr-3"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Todos' }"
                    :icon="['fas', 'file']"  />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Todos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Todos' }"
                    >Todos os documentos</span>
                </a>
              </router-link>
            </li>

            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Models' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mb-2 mobile-btn-pained">
              <router-link :to="{ name: 'Rascunhos' }" exact>
                <a @click="setActiveTab('Rascunhos'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <i :class="{ 'text-black': activeTab === '' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== '' }" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab === 'Rascunhos' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'Rascunhos' }"
                    >Rascunhos</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>

        <div class="d-flex justify-content-center mt-2">
          <img src="../../assets/img/line_down.png" style="width: 95%; height: 2px;">
        </div>

        <ul class="nav ms-3">
          <div class="d-flex mt-2">
            <li class="nav-item navbar-brand p0-extend">
              <span class="sidenav-normal font-docs text-white header-size" style="font-size: 14px">Gerenciamento</span>
            </li>
          </div>
        </ul>

        <li class="nav-item">

          <ul class="nav">
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mb-2 mobile-btn-pained">
              <router-link :to="{ name: 'Setores' }" exact>
                <a @click="setActiveTab('Setores'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3" :class="{ 'text-black': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Setores' }"
                  :icon="['fas', 'id-badge']" style="font-size: 19px;"/>
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Setores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Setores' }"
                    >Setores</span>
                </a>
              </router-link>
            </li>
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mobile-btn-pained">
              <router-link :to="{ name: 'Servidores' }" exact>
                <a @click="setActiveTab('Servidores'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                    <font-awesome-icon class="mr-3"  :class="{ 'text-black': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Servidores' }"
                    :icon="['fas', 'user-group']" flip="horizontal" />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Servidores' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Servidores' }"
                    >Servidores</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>
        <div class="d-flex justify-content-center">
          <img src="../../assets/img/line_down.png" style="width: 95%; height: 2px;">
        </div>
        <li class="nav-item">

          <ul class="nav">
            <li
              :class="{ 'active': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mobile-btn-pained mt-2 mb-2">
              <router-link :to="{ name: 'Suporte' }" exact>
                <a @click="setActiveTab('Suporte'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"  :class="{ 'text-black': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Suporte' }"
                    :icon="['fas', 'phone']" />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab.split('/')[0] === 'Suporte' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab.split('/')[0] !== 'Suporte' }"
                    >Suporte</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>

        <div class="d-flex justify-content-center">
          <img src="../../assets/img/line_down.png" style="width: 95%; height: 2px;">
        </div>
        <li class="nav-item">

          <ul class="nav">
            <li
              :class="{ 'active': activeTab === 'profile' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home' }"
              class="nav-item-mobile box-link mobile-btn-pained mt-2 mb-2">
              <router-link :to="{ name: 'profile' }" exact>
                <a @click="setActiveTab('profile'); this.Store.isPinned = false" class="nav-link text-white" style="padding-top: 0.45rem !important;">
                  <font-awesome-icon class="mr-3"  :class="{ 'text-black': activeTab === 'profile' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'profile' }"
                    :icon="['fas', 'user']" />
                  <span class="sidenav-normal font-docs fonts-docs-size-mobile"
                    :class="{ 'text-black': activeTab === 'profile' && this.$route.path != systemNow + '/' && this.$route.path != systemNow + '/Home', 'text-white': activeTab !== 'profile' }"
                    >Meu perfil</span>
                </a>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    </div>

    <div v-if="!this.Store.mobile" class="d-flex justify-content-center w-100" style="position: absolute; bottom: 0;">
      <button v-if="this.Store.isPinned" class="btn" @click="toggle(1)">
        <i class="mr-2 fa fa-chevron-left text-white" aria-hidden="true"></i>
        <span class="text-white">Recolher</span>
      </button>
      <button v-if="!this.Store.isPinned" class="btn" @click="toggle(2)">
        <i class="fa fa-chevron-right text-white" aria-hidden="true"></i>
      </button>
    </div>

    <div v-if="this.Store.mobile" class="d-flex justify-content-start w-100 justify-content-start align-items-center" style="position: absolute; bottom: 20px; left: 20px;" @click="logout()">
        <img src="../../assets/img/logoutred.png" style="cursor:pointer; width: 14px; height:14px" />
        <span class="ml-1" style="color:#EA868F; font-size: 16px"> Sair </span>
    </div>

  </div>
</template>
