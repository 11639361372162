<script>
import { AxiosAPI } from '@/axios';
import Editor from '@tinymce/tinymce-vue';
import { useMainStore } from '../../stores/index';
import ModalEdit from '../documents/Modals/Modal_Edit.vue';
export default {
  name: 'DashboardDefault',
  components: {
    ModalEdit, Editor
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      user_name: '',
      user: [],
      emailoculto: '',
      password: '********',
      edit: false,
      componenteHeight: '93vh',
      editorContent: '',
      editorConfig: {
        min_height: 800,
        height: 800,
        max_height: 800,
        language: 'pt_BR',
        plugins: 'link image table quickbars',
        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent  | advtablerownumbering | quickimage | fontsizeinput'
      },
      progress: 0
    };
  },
  mounted () {
    this.User();

    this.emitter.on('edit', (edit) => {
      console.log(edit);
      this.User();
    });
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },

  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Profile');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    async User () {
      await this.Store.Get_user();
      this.user = this.Store.user;
      this.user_name = this.user.name;
      this.emailoculto = this.user.email;
      this.editorContent = this.user.footer;
      console.log(this.user);
    },

    /* async Data_User (email) {
      const posicaoArroba = email.indexOf('@');
      const tresPrimeirasLetras = email.substring(0, 3);
      const asteriscosRestantes = '*'.repeat(posicaoArroba - 3);
      this.emailoculto = tresPrimeirasLetras + asteriscosRestantes + email.substring(posicaoArroba);
    },
 */
    async editUser (value) {
      if (value === 1) {
        const interval = setInterval(() => {
          if (this.progress < 90) {
            this.progress += 10;
          }
        }, 1000);
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', this.user_name);
        formData.append('email', this.emailoculto);
        formData.append('footer', this.editorContent);
        if (this.password !== '') {
          formData.append('password', this.password);
          formData.append('password_confirmation', this.password);
        }
        await AxiosAPI.post('/api/users/' + this.Store.user.id, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          console.log(resp);
          this.User();
          this.edit = false;
          this.progress = 100;
          setTimeout(() => {
            clearInterval(interval);
            this.progress = 0;
            this.$refs.hiddenButtonProfile.click();
          }, 2000);
        });
      }
      if (value === 2) {
        this.user = this.Store.user;
        this.emailoculto = this.user.email;
        this.password = '********';
        this.editorContent = this.user.footer;
      }
    },

    edit_image () {
      this.$refs.ModalEdit.openModal_avatar();
    },

    edit_name () {
      this.$refs.ModalEdit.openModal_name();
    },

    edit_email () {
      this.$refs.ModalEdit.openModal_email();
    },

    edit_password () {
      this.$refs.ModalEdit.openModal_password();
    },

    showSuccess_edit () {
      this.$toast.add({ severity: 'success', summary: 'Edição realizada com sucesso', detail: 'A edição foi um sucesso!', life: 3000 });
    },

    showErro_edit () {
      this.$toast.add({ severity: 'error', summary: 'Edição não realizada', detail: 'Contate o administrador para verificar o error', life: 3000 });
    }

  }
};
</script>
<style>
.image-container {
    position: relative;
    cursor: pointer;
}

.hover-image {
    width: 300px;
    transition: opacity 0.3s;
}

.icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}

.icon {
    width: 50px;
}

.image-container:hover .hover-image {
    opacity: 0.4;
}

.image-container:hover .icon-container {
    opacity: 1;
}
</style>
<template>
    <div class='py-4 container-fluid' id="Profile" :style="{ minHeight: componenteHeight }">
        <ModalEdit ref='ModalEdit'></ModalEdit>
        <div class="row">
            <h4 class="font-docs text-docs-black ml-1"><b>Meu perfil</b></h4>
        </div>
        <div class='d-flex justify-content-center container'>
          <div class="row">
                <div class='d-flex justify-content-center image-container col-sm mb-5' style='width: 100%;height: 200px;'>
                  <div class="d-grid" @click='edit_image()'>
                    <img class='hover-image' :src='this.Store.user.profile_image' style='width: 200px;border-radius:50%; border: 1px solid #0D6EFD'>
                        <div class='icon-container'>
                            <i class='fa fa-edit'></i>
                            <span class='text-dark'>Editar</span>
                        </div>
                        <div class="d-grid text-center">
                            <span class='font-docs text-dark'><b>Clique para editar</b></span>
                            <span class='font-docs'>Formatos: JPEG, PNG.</span>
                        </div>
                      </div>
                    </div>

                <div class="col-sm" style="width: 100%">
                    <label class="label-docs">Nome completo</label>
                    <input class='mb-3 multisteps-form__input form-control' type='text' :placeholder='user_name' v-model="user_name" :disabled="!edit" />

                <div class="d-flex align-items-center" style="background-color: #FFF3CD;">
                    <i class="fa fa-exclamation-triangle ml-3 mr-2" aria-hidden="true" style="color: #664D03"></i>
                    <span class="font-docs" style="color: #664D03">Atenção! Ao alterar seu e-mail e nome, caso existam documentos pendentes de assinatura, não poderão ser mais assinados.</span>
                </div>
                <label class="label-docs mt-3">Email</label>
                    <input class='mb-3 multisteps-form__input form-control' type='text' :placeholder='emailoculto' v-model="emailoculto" :disabled="!edit" />

                <label class="label-docs mt-3">Senha</label>
                    <input class='mb-3 multisteps-form__input form-control' type='text' placeholder='********' v-model="password" :disabled="!edit" />

                <div class='row mt-2'>
                  <span class="font-docs">Rodapé <img src="../../assets/img/info.png" alt=""></span>
                  <div>
                    <Editor style='border: 2px solid;' v-model='editorContent'
                              api-key='lhbldb066rsly0ci0sul37fjp28wklje4c2nh83d9eyc6w9z' :init='editorConfig' :disabled="!edit"/>
                  </div>
                </div>

                <div v-if="!edit" class="d-flex justify-content-end">
                    <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="edit = true;this.password = '';">Editar</button>
                </div>
                <div v-if="edit" class="d-flex justify-content-end">
                    <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="editUser(2);edit = false;">Cancelar</button>
                    <button class="btn mt-3 ml-3 font-docs text-white background-Docs" data-toggle="modal" data-target="#LoadingProfile" @click="editUser(1)">Confirmar edição</button>
                </div>

                <div class="modal fade" id="LoadingProfile" tabindex="-1" role="dialog" aria-labelledby="LoadingProfile" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
                    <button type="button" ref="hiddenButtonProfile" class="close d-none" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                    <div class="modal-body">
                      <span class="font-docs text-white d-flex justify-content-center">Editando perfil, Por favor, aguarde...</span>
                      <div class="progress" style="height: 30px;">
                        <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
                        animation: progress-bar-stripes 1s linear infinite !important;
                        background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
                        background-size: 0.5rem 1rem;
                        transition: width .6s ease;
                        background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
    </div>
    </div>
</template>
