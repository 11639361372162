<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import ProgressBarDocsEdit from './ProgressBarEdit.vue';
import Editor from '@tinymce/tinymce-vue';
import RequiredFill from '../Modals/RequiredFill.vue';
import Cookie from 'js-cookie';
export default {
  name: 'EditMain',
  components: { Editor, ProgressBarDocsEdit, RequiredFill },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      subject: '',
      editorContent: '',
      editorConfig: {
        min_height: 800,
        height: 800,
        max_height: 800,
        language: 'pt_BR',
        plugins: 'link image table quickbars',
        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent  | advtablerownumbering | quickimage | fontsizeinput'
      },
      typeDoc: [{ id: 0, title: 'Selecione' }],
      typeSelect: 0
    };
  },
  mounted () {
    this.GetTypes();
  },
  watch: {
  },
  methods: {
    async GetTypes () {
      const typesAPI = await this.Store.GetTypes();
      this.Types = typesAPI;
      this.LoadingType = true;

      const typeNone = this.Store.Types;
      if (!typeNone.some(obj => obj.id === 0)) {
        typeNone.push({ id: 0, title: 'Selecione' });
      }
      typeNone.sort((a, b) => a.id - b.id);
      this.typeDoc = typeNone;

      this.typeSelect = this.Store.documentDataEdit.typeDoc;
      this.subject = this.Store.documentDataEdit.subject;
      this.editorContent = this.Store.documentDataEdit.body;
    },
    nextProgress () {
      if (this.typeSelect === 0) {
        this.$refs.RequiredFill.openModal();
      }
      if (this.subject === '') {
        this.$refs.RequiredFill.openModal();
      }
      if (this.editorContent === '') {
        this.$refs.RequiredFill.openModal();
      }
      if (this.typeSelect !== 0 && this.subject !== '' && this.editorContent !== '') {
        const data = {
          typeDoc: this.typeSelect,
          titleDoc: this.subject,
          contentDoc: this.editorContent
        };
        const CookieData = JSON.stringify(data);
        this.Store.documentDataEdit.typeDoc = this.typeSelect;
        this.Store.documentDataEdit.subject = this.subject;
        this.Store.documentDataEdit.body = this.editorContent;
        Cookie.set('DataDocumentEdit', CookieData);
        this.Store.progressEdit += 1;
      }
    },

    backProgress () {
      this.Store.progressEdit -= 1;
    }
  }
};
</script>
<style>
.box-model{
  width: 247px !important;
  height: 138px;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  cursor: pointer;
}
.box-img{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #E9ECEF;
}
.box-img-new{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
}
@media screen and (max-width: 1450px) {
.box-model{
  width: 220px !important;
}
}

@media screen and (max-width: 1380px) {

.box-model{
  width: 200px !important;
}
}

@media screen and (max-width: 1200px) {
.box-model{
  width: 220px !important;
}
}

@media screen and (max-width: 1180px) {
  .box-model{
  width: 200px !important;
}
}

@media screen and (max-width: 1050px) {
  .box-model{
  width: 180px !important;
}
}
@media screen and (max-width: 992px) {
  .box-model{
  width: 150px !important;
}
}
@media screen and (max-width: 850px) {
  .box-model{
  width: 150px !important;
}
}
.tox {
  border-color: #CED4DA !important;
}
</style>
<template>
  <div class="container">
    <RequiredFill ref="RequiredFill" />
    <ProgressBarDocsEdit />
    <div class="container mt-3">
      <div v-if="this.Store.blockBySigner" class="d-flex justify-content-between align-items-center" style="background-color:#B6D4FE;height: 50px;">
          <div>
            <font-awesome-icon :icon="['fas', 'circle-info']" class="ml-2 mr-2" style="color:#084298; font-size: 15px;" />
            <span class="font-docs" style="color: #084298"> <b> As informações abaixo não podem ser modificadas, pois o documento já foi assinado por uma das partes envolvidas.</b> </span>
          </div>
        </div>
      <div class="row">
        <h4 class="font-docs text-docs-black ml-1"><b>Dados do documento</b></h4>
      </div>

    <div class='row' style="margin-bottom: 5rem">
        <div class='col-sm-6 mt-1'>
            <label class="label-docs text-docs-black">Tipo</label>
                <select id='choices-category' v-model='typeSelect' class='my_form font-docs' name='choices-category' style="font-size: 16px;" :disabled="this.Store.blockBySigner">
                    <option v-for='option in typeDoc' :key='option.title' :value='option.id'>
                        {{ option.title }}
                    </option>
                </select>
            </div>

            <div class='col-sm-6 mt-1'>
                <label class="label-docs text-docs-black">Titulo <span style="color: red;">*</span></label>
                <input class="my_form text-bold" @input="subject = subject.toUpperCase()" v-model="subject" placeholder="Ex: Ofício judicial" type="text" :disabled="this.Store.blockBySigner">
            </div>
      </div>
      <hr>
      <div v-for="docTitle in typeDoc" :key="docTitle" class="row">
        <h4 v-if="typeSelect === docTitle.id && typeSelect === 0" class="font-docs text-docs-black ml-1 mt-3"><b>Aguardando tipo *</b></h4>
        <h4 v-if="typeSelect === docTitle.id && typeSelect !== 0" class="font-docs text-docs-black ml-1 mt-3"><b>{{ docTitle.title }}</b></h4>
      </div>
      <div v-if="!this.Store.blockBySigner" class='row mt-2'>
        <span>Textarea <img src="../../../assets/img/info.png" alt=""></span>
        <div>
          <Editor style='border: 2px solid;' v-model='editorContent'
                    api-key='lhbldb066rsly0ci0sul37fjp28wklje4c2nh83d9eyc6w9z' :init='editorConfig' />
      </div>
        </div>
        <div v-if="this.Store.blockBySigner" class='row mt-2'>
        <div style="height: 100vh">
          <iframe :src="this.Store.host + this.Store.DocumentDespachComplete.complete_file" height="100%" width="100%"></iframe>
      </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
        </div>
      </div>
  </div>

</template>
