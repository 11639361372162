<script>
import { useMainStore } from '../../../stores/index';
export default {
  name: 'TodoList',
  components: { },
  props: {
    ExistCheckList: {
      type: Object
    }
  },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  computed: {
    ExistCheckLists () {
      if (this.ExistCheckList) {
        return true;
      } else {
        return false;
      }
    }
  },
  data () {
    return {
      lists: this.ExistCheckList ? this.ExistCheckList : [],
      newItem: {
        description: '',
        is_checked: false,
        isEditingItem: false
      }
    };
  },

  methods: {
    addList () {
      this.lists.push({
        title: 'CheckList',
        isEditingText: false,
        items: [
          {
            description: '',
            is_checked: false,
            isEditingItem: true
          }
        ],
        addItemInput: false
      });
    },

    addItem (listIndex) {
      this.lists[listIndex].items.push(this.newItem);
      this.newItem = {
        description: '',
        isEditingItem: false,
        is_checked: false
      };
    },

    editTitle (index) {
      this.lists[index].isEditingText = true;
    },

    saveTitle (index) {
      this.lists[index].isEditingText = false;
    },

    editItem (listIndex, itemIndex) {
      this.lists[listIndex].items[itemIndex].isEditingItem = true;
    },
    saveItemTitle (listIndex, itemIndex) {
      this.lists[listIndex].items[itemIndex].isEditingItem = false;
    },

    removeList (index) {
      this.lists.splice(index, 1);
    },

    removeItem (listIndex, itemIndex) {
      this.lists[listIndex].items.splice(itemIndex, 1);
    },

    calculateProgress (list) {
      if (list.items.length <= 0) {
        return 0;
      } else if (list.items.length === undefined) {
        return 0;
      } else {
        const totalItems = list.items.length;
        const completedItems = list.items.filter(item => item.is_checked).length;
        return totalItems > 0 ? Math.round((completedItems / totalItems) * 100) : 0;
      }
    },

    ItemsChecked (list) {
      if (list.items.length <= 0) {
        return 0;
      } else if (list.items.length === undefined) {
        return 0;
      } else {
        const completedItems = list.items.filter(item => item.is_checked).length;
        return completedItems;
      }
    }
  }
};
</script>
<style>
.CheckBox-docs {
    border-radius: 5px !important;
    border-color: #F1860B !important;
}
</style>
<style scoped>
.progress-bar {
    background-color: #F1860B !important;
    border-radius: 4px !important;
    height: 16px !important;
}
.progress {
    border-radius: 4px !important;
    height: 16px !important;
}
</style>
<template>
    <div>
        <div v-for="(list, index) in lists" :key="index" class="mt-3">
            <div class="d-flex justify-content-between">
              <div class="w-100">
                <i class="fa-regular fa-rectangle-list mr-2" style="font-size: 25px"></i>
                <span v-if="!list.isEditingText" class="font-docs" style="cursor:pointer" @click="editTitle(index)">{{ list.title }}</span>

                <input
                    v-if="list.isEditingText"
                    v-model="list.title"
                    class="font-docs w-90"
                    @blur="saveTitle(index)"
                    @keyup.enter="saveTitle(index)"
                    type="text"
                    style="font-size: 16px; border: none; outline: none; background: transparent;"
                />
                </div>

                <i class="fa-solid fa-trash Color-Docs mr-3" style="font-size: 20px; cursor:pointer" @click="removeList(index)"></i>
            </div>

            <div class="progress mt-2">
                <div class="progress-bar" role="progressbar" :style="'width:'+ calculateProgress(list) +'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="font-docs" style="color: #465668">Progresso</div>
                <div class="font-docs" style="color: #465668"> {{ ItemsChecked(list) }}/{{ list.items.length }}</div>
            </div>

            <div class="w-100">
              <input v-if="list.addItemInput" type="text" v-model="newItem.description" placeholder="Adicionar item" class="w-100 my_form" @keyup.enter="addItem(index); list.addItemInput = false" />
            </div>

            <div v-for="(item, itemIndex) in list.items" :key="itemIndex" class="mt-2">
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center w-100">
                        <Checkbox v-model="item.is_checked" class="border-color" :inputClass="{'background-Docs CheckBox-docs': item.is_checked}" binary/>
                        <span v-if="!item.isEditingItem" class="font-docs ml-2" @click="editItem(index, itemIndex)">
                            {{ item.description }}
                        </span>

                        <input
                            v-if="item.isEditingItem"
                            v-model="item.description"
                            @blur="saveItemTitle(index, itemIndex)"
                            @keyup.enter="saveItemTitle(index, itemIndex)"
                            placeholder="Primeiro item"
                            type="text"
                            class="font-docs ml-2  w-90"
                            style="font-size: 16px; border: none; outline: none; background: transparent;"
                        />
                        </div>
                    <i class="fa-solid fa-trash Color-Docs mr-3" style="font-size: 20px; cursor:pointer" @click="removeItem(index, itemIndex)"></i>
                </div>
            </div>

            <div class="d-flex align-items-center mt-2" style="cursor: pointer" @click="list.addItemInput = true">
                <i class="fa-solid fa-plus mr-2 Color-Docs"></i>
                <span class="font-docs Color-Docs">Adicionar item</span>
            </div>
        </div>

        <div class="d-flex align-items-center mt-3">
            <button class="btn Color-Docs font-docs" style="border: 1px solid #F1860B;cursor: pointer" @click="addList">
                Adicionar checklist
            </button>
        </div>
    </div>
</template>
<style>
</style>
