<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import ProgressBarDocs from './ProgressBar.vue';
export default {
  name: 'DashboardDefault',
  components: { ProgressBarDocs },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      Types: [],
      LoadingType: false
    };
  },
  mounted () {
    this.GetTypes();
  },
  watch: {
  },
  methods: {
    async GetTypes () {
      const typesAPI = await this.Store.GetTypes();
      this.Types = typesAPI;
      this.LoadingType = true;
    },
    nextProgress (value) {
      this.Store.progress += 1;
      this.Store.documentData.typeDoc = value;
      this.Store.documentData.user_id = this.Store.user.id;
    }
  }
};
</script>
<style>
.box-model{
  width: 247px !important;
  height: 138px;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  cursor: pointer;
  background-color: white;
}
.box-img{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #E9ECEF;
}
.box-img-new{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
}
@media screen and (max-width: 1450px) {
.box-model{
  width: 220px !important;
}
}

@media screen and (max-width: 1380px) {

.box-model{
  width: 200px !important;
}
}

@media screen and (max-width: 1200px) {
.box-model{
  width: 220px !important;
}
}

@media screen and (max-width: 1180px) {
  .box-model{
  width: 200px !important;
}
}

@media screen and (max-width: 1050px) {
  .box-model{
  width: 180px !important;
}
}
@media screen and (max-width: 992px) {
  .box-model{
  width: 150px !important;
}
}
@media screen and (max-width: 850px) {
  .box-model{
  width: 150px !important;
}
}
</style>
<template>
  <div>
    <ProgressBarDocs />
    <div v-if="!LoadingType" class="row d-flex justify-content-center align-items-center mt-5">
      <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:50%"></ProgressBar>
      <span class="d-flex justify-content-center align-items-center font-docs">carregando...</span>
    </div>
    <div v-if="LoadingType">
    <div class="container mt-4">
      <div class="row" style="justify-content:space-between">
        <div class="col-6 col-sm-4 box-model" style="background-color: white; border: 2px solid #F1860B; border-style: dashed;" @click="nextProgress(0)">
          <div class="d-flex justify-content-center align-items-center box-img-new background-Docs">
            <font-awesome-icon :icon="['fas', 'plus']" class="text-white" />
          </div>
          <span class="font-docs mt-2"><b>Novo Modelo</b></span>
        </div>
        <div v-for="(docType, index) in Types" :key="index" class="col-6 col-sm-4 box-model" @click="nextProgress(index+1)" >
          <div class="box-img">
            <img src="../../../assets/img/filetype.png" alt="">
          </div>
          <span class="font-docs mt-2"><b>{{docType.title}}</b></span>
        </div>
      </div>
    </div>

  </div>
</div>
</template>
