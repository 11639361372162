<script>
import { useMainStore } from '../../../stores/index';

export default {
  name: 'SignerSituation',
  props: {
    StatusSituation: {
      type: Object,
      required: false
    }
  },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  }
};
</script>

<template>
  <div>
    <div v-if="StatusSituation.data !== undefined">
    <div v-if="StatusSituation.data.signers.length >= 1 && StatusSituation.data.attachments.length >= 1">
        <div v-for="(attachmentsSigner, index) in StatusSituation.data.attachments" :key="index">
            <div v-if="StatusSituation.data.signers.some(signer => signer.user.id === Store.user.id) && attachmentsSigner.signers.some(attachmentSigner => attachmentSigner.user_id === Store.user.id)">
                <div v-if="index === 0">
                <div v-for="penSigner in StatusSituation.data.signers" :key="penSigner">
                    <div v-for="AttachSigner in attachmentsSigner.signers" :key="AttachSigner">
                    <div v-if="penSigner.user.id === Store.user.id && AttachSigner.user_id === Store.user.id">
                        <div
                        v-if="penSigner.signed_at === null && AttachSigner.signed_at === null"
                        class="d-flex align-items-center justify-content-center mr-3"
                        style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Aguardando assinatura(s)</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at === null"
                        class="d-flex align-items-center justify-content-center mr-3"
                        style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at === null && AttachSigner.signed_at !== null"
                        class="d-flex align-items-center justify-content-center mr-3"
                        style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null && this.$route.name !== 'Assinaturas'"
                        class="d-flex align-items-center justify-content-center"
                        style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Em movimentação</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null && this.$route.name === 'Assinaturas'"
                        class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Assinado</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div v-if="StatusSituation.data.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                <div v-if="index === 0">
                    <div v-for="penSigner in StatusSituation.data.signers" :key="penSigner">
                        <div v-if="penSigner.user.id === this.Store.user.id">
                            <div v-if="penSigner.signed_at === null" class="d-flex align-items-center justify-content-center mr-3" style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                                <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                            </div>
                            <div
                            v-else-if="penSigner.signed_at !== null && this.$route.name === 'Assinaturas'"
                            class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;"
                            >
                            <span class="font-docs font-weight-bold">Assinado</span>
                            </div>
                            <div v-else-if="penSigner.signed_at !== null" class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                                <span class="font-docs font-weight-bold">Em movimentação</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!StatusSituation.data.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                <div v-if="index === 0">
                    <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Em movimentação</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="StatusSituation.data.signers.length >= 1 && StatusSituation.data.attachments.length <= 0">
        <div v-if="StatusSituation.data.signers.some(signer => signer.user.id === this.Store.user.id)">
            <div v-for="penSigner in StatusSituation.data.signers" :key="penSigner">
                <div v-if="penSigner.user.id === this.Store.user.id">
                    <div v-if="penSigner.signed_at === null" class="d-flex align-items-center justify-content-center mr-3" style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                    </div>
                    <div
                    v-else-if="penSigner.signed_at !== null && this.$route.name === 'Assinaturas'"
                    class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;"
                    >
                    <span class="font-docs font-weight-bold">Assinado</span>
                    </div>
                    <div v-else-if="penSigner.signed_at !== null" class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Em movimentação</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="StatusSituation.data.signers.length <= 0 && StatusSituation.data.attachments.length <= 0">
        <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
            <span class="font-docs font-weight-bold">Em movimentação</span>
        </div>
    </div>

    <div v-if="StatusSituation.data.signers.length <= 0 && StatusSituation.data.attachments.length >= 1">
        <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
            <span class="font-docs font-weight-bold">Em movimentação</span>
        </div>
    </div>
  </div>
  <div v-else>
    <div v-if="StatusSituation.signers.length >= 1 && StatusSituation.attachments.length >= 1">
        <div v-for="(attachmentsSigner, index) in StatusSituation.attachments" :key="index">
            <div v-if="StatusSituation.signers.some(signer => signer.user.id === Store.user.id) && attachmentsSigner.signers.some(attachmentSigner => attachmentSigner.user_id === Store.user.id)">
                <div v-if="index === 0">
                <div v-for="penSigner in StatusSituation.signers" :key="penSigner">
                    <div v-for="AttachSigner in attachmentsSigner.signers" :key="AttachSigner">
                    <div v-if="penSigner.user.id === Store.user.id && AttachSigner.user_id === Store.user.id">
                        <div
                        v-if="penSigner.signed_at === null && AttachSigner.signed_at === null"
                        class="d-flex align-items-center justify-content-center mr-3"
                        style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Aguardando assinatura(s)</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at === null"
                        class="d-flex align-items-center justify-content-center mr-3"
                        style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at === null && AttachSigner.signed_at !== null"
                        class="d-flex align-items-center justify-content-center mr-3"
                        style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                        </div>
                        <div
                        v-else-if="penSigner.signed_at !== null && AttachSigner.signed_at !== null"
                        class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;"
                        >
                        <span class="font-docs font-weight-bold">Assinado</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div v-if="StatusSituation.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                <div v-if="index === 0">
                    <div v-for="penSigner in StatusSituation.signers" :key="penSigner">
                        <div v-if="penSigner.user.id === this.Store.user.id">
                            <div v-if="penSigner.signed_at === null" class="d-flex align-items-center justify-content-center mr-3" style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                                <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                            </div>
                            <div
                            v-else-if="penSigner.signed_at !== null"
                            class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;"
                            >
                            <span class="font-docs font-weight-bold">Assinado</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!StatusSituation.signers.some(signer => signer.user.id === this.Store.user.id) && !attachmentsSigner.signers.some(attachmentsigner => attachmentsigner.user_id === this.Store.user.id)">
                <div v-if="index === 0">
                    <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Em movimentação</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="StatusSituation.signers.length >= 1 && StatusSituation.attachments.length <= 0">
        <div v-if="StatusSituation.signers.some(signer => signer.user.id === this.Store.user.id)">
            <div v-for="penSigner in StatusSituation.signers" :key="penSigner">
                <div v-if="penSigner.user.id === this.Store.user.id">
                    <div v-if="penSigner.signed_at === null" class="d-flex align-items-center justify-content-center mr-3" style="width: 205px;height: 30px;background-color: #FFF3CD;color: #997404;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Aguardando sua assinatura</span>
                    </div>
                    <div
                    v-else-if="penSigner.signed_at !== null && this.$route.name === 'Assinaturas'"
                    class="d-flex align-items-center justify-content-center" style="width: 100px;height: 30px;background-color: #D1E7DD;color: #198754;border-radius: 10px;"
                    >
                    <span class="font-docs font-weight-bold">Assinado</span>
                    </div>
                    <div v-else-if="penSigner.signed_at !== null" class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
                        <span class="font-docs font-weight-bold">Em movimentação</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="StatusSituation.signers.length <= 0 && StatusSituation.attachments.length <= 0">
        <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
            <span class="font-docs font-weight-bold">Em movimentação</span>
        </div>
    </div>

    <div v-if="StatusSituation.signers.length <= 0 && StatusSituation.attachments.length >= 1">
        <div class="d-flex align-items-center justify-content-center" style="width: 160px;height: 30px;background-color: #E7F1FF;color: #0A58CA;border-radius: 10px;">
            <span class="font-docs font-weight-bold">Em movimentação</span>
        </div>
    </div>
  </div>
</div>
</template>
